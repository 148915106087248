import React from "react";
import { HyperLegerServicesList } from "../../scripts/ServicesLists";

export default function OurServices() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3">
                  Services for Building <span>Hyperledger Blockchains</span>
                </h2>
                <p className="">
                  Using a dedicated delivery strategy, we put high-quality
                  solutions into action.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {HyperLegerServicesList.map((data, index) => (
              <div className="col-lg-4 col-md-4 my-4" key={index}>
                <div className="hyperleger-service-inner style-3-for-hyperleger">
                  <div className="details">
                    <h4 className="mb-4">{data.title}</h4>
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
