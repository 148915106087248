import React from "react";

export default function About() {
  return (
    <div>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className="about-area pd-top-120 pd-bottom-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="about-thumb-inner  me-xl-5 "
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="main-img"
                  src="assets/img/images/joinusdapp.jpg"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-7 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title ">
                {/* <h6 className='sub-title'>ABOUT US</h6>*/}
                <h2 className="sectitle-lg ">
                  Join Us in Creating a <span>Revolutionary Decentralized Future!</span>
                </h2>
                <p className="content my-3 mb-xl-5">
                  Making an app using traditional methods is different from
                  building a decentralized app using blockchain technology.
                  Dapps are a great way for businesses to benefit from
                  decentralized applications (DApps) because they are
                  trustworthy, decentralized, and built using open-source code.
                  A robust application that has the potential to revolutionize
                  technology emerged from this process.<br/> Using these capabilities
                  to their full potential, the developers at our blockchain
                  company construct Dapps that provide value for companies.
                  Smart contracts, decentralized exchanges, and NFT markets are
                  all areas in which our team of skilled Dapp developers excels.
                  Using blockchain technology, we create Dapps that are both
                  secure and scalable, tailored to each client's specific
                  business needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </div>
  );
}
