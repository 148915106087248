import React from 'react'
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";

export default function Counter() {
  return (
    <div>
       {/*================= counter area start {/*=================*/}
       <div className='counter-area pd-top-60 pd-bottom-60'>
       <div className='container'>
         <div className='row'>
         <div className="section-title ">
               <p className=''>
               In our smart contract development company, honesty, knowledge, and originality are treasured by every member. We think the only way to get the greatest results and make the most efficient solutions is to become a part of the client's team and share their values. Experience our services firsthand!
               </p>
             </div>
           <div className='col-lg-6 col-md-6 my-4'>
             <div className=''>
               <h2 className=' mt-3 mb-2'>
                 <TrackVisibility once>
                   {({ isVisible }) =>
                     isVisible && (
                       <span className='counter text-base'>
                         <CountUp delay={0} start={0} end={3} />+
                       </span>
                     )
                   }
                 </TrackVisibility>
               </h2>
               <p className=''>years of expertise in the realm of Blockchain research and development.</p>
             </div>
           </div>
           <div className='col-lg-6 col-md-6 my-4'>
             <div className=''>
               <h2 className=' mt-3 mb-2'>
                 <TrackVisibility once>
                   {({ isVisible }) =>
                     isVisible && (
                       <span className='counter text-base'>
                         <CountUp delay={0} start={0} end={10} />+
                       </span>
                     )
                   }
                 </TrackVisibility>
               </h2>
               <p className=''>leading minds in the Blockchain field are part of our group.</p>
             </div>
           </div>
           <div className='col-lg-6 col-md-6 my-4'>
             <div className=''>
               <h2 className=' mt-3 mb-2'>
                 <TrackVisibility once>
                   {({ isVisible }) =>
                     isVisible && (
                       <span className='counter text-base'>
                         <CountUp delay={0} start={0} end={500} />+
                       </span>
                     )
                   }
                 </TrackVisibility>
               </h2>
               <p className=''>effectively finished tasks</p>
             </div>
           </div>
           <div className='col-lg-6 col-md-6 my-4'>
             <div className=''>
               <h2 className=' mt-3 mb-2'>
                 <TrackVisibility once>
                   {({ isVisible }) =>
                     isVisible && (
                       <span className='counter text-base'>
                         <CountUp delay={0} start={0} end={10} />+
                       </span>
                     )
                   }
                 </TrackVisibility>
               </h2>
               <p className=''>blockchains mastered</p>
             </div>
           </div>
         </div>
       </div>
     </div>

     {/*{/*================= counter area end {/*=================*/}
    </div>
  )
}
