import React from 'react'
import NavbarTwo from '../../components/NavbarTwo'
import FooterThree from '../../components/FooterThree'
import SubServiceBanner from '../../common_components/SubServiceBanner'
import CtaSec from '../../common_components/CtaSec'
import Counter from './Counter'
import OurServices from './OurServices'
import StatsSec from './StatsSec'
import WhyChooseUs from './WhyChooseUs'
import WeWork from '../../common_components/WeWork'
import ContactSec from '../../common_components/ContactSec'

export default function GenerativeAi() {
  return (
    <div>
      <NavbarTwo/>
      <SubServiceBanner
      subheading="Service"
      title="Generate New Value for Your"
      blueText="Business Through AI"
      />
      <Counter/>
      <OurServices/>
      <StatsSec/>
      <CtaSec/>
      <WhyChooseUs/>
      <WeWork/>
      <ContactSec/>
      <FooterThree/>
    </div>
  )
}
