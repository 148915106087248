import React from 'react'

export default function ContactSec() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
            <div className="mb-5">
            <div className="section-title ">
              <h2 className="sectitle-lg mb-3">
                 Let’s <span>talk</span>
              </h2>
              <p className='mb-3'>Please fill out the form or email at info@tabs.com</p>
              <h5 className='mb-3' ><span style={{color:'#246bfd', fontSize:'25px'}}>1800+</span> Strong Workforce</h5>
              <h5 className='mb-3' ><span style={{color:'#246bfd', fontSize:'25px'}}>250+</span> Active Customers</h5>
              <h5 className='mb-3' ><span style={{color:'#246bfd', fontSize:'25px'}}>100+</span> Certified Data Consultants</h5>
            </div>
          </div>
            </div>
            <div className="col-lg-6 col-md-6">
            <div className="section-title mb-0">
            <form className="mt-4">
              <div className="row">
                <div className="col-lg-12">
                  <div className="single-input-inner style-border">
                    <input type="text" placeholder="Your Name" />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-input-inner style-border">
                    <input type="text" placeholder="Your Email" />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-input-inner style-border">
                    <input type="text" placeholder="Your Phone" />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-input-inner style-border">
                    <textarea
                      placeholder="Message"
                      rows={3}
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <a
                    className="btn btn-black mt-0 w-100 border-radius-5"
                    href="/"
                  >
                    Submit now
                  </a>
                </div>
              </div>
            </form>
          </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  )
}
