import React, { useState } from "react";
import axios from "axios";

export default function SubServiceBanner(props) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post('http://localhost:3000/send', formData)
      .then((response) => {
        console.log(response.data);
        alert("Message sent successfully!");
      })
      .catch((error) => {
        console.error(error);
        alert("An error occurred, please try again.");
      });

    setFormData({ name: "", email: "", phone: "", message: "" });
  };

  return (
    <div>
      <div
        className="banner-area bg-relative banner-area-1 pd-bottom-80 bg-cover"
        style={{ backgroundImage: 'url("./assets/img/banner/0.png")' }}
      >
        <div className="container custom-container">
          <div className="row">
            <div className="col-xl-8 col-md-6 align-self-center">
              <div className="banner-inner pe-xl-5">
                <h6
                  className="subtitle"
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  {props.subheading}
                </h6>
                <h2
                  className="title-lg"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  {props.title}
                  <span> {props.blueText}</span>
                </h2>
                <p
                  className="content pe-xl-5"
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                >
                  {props.paragraph}
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 align-self-center bg-white px-3 py-3 border-radius-5">
              <div className="section-title mb-0">
                <h2 className="title text-center">
                  Request For <span>Proposal</span>
                </h2>
                <form className="mt-4" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="single-input-inner style-border">
                        <input
                          type="text"
                          placeholder="Your Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-input-inner style-border">
                        <input
                          type="email"
                          placeholder="Your Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-input-inner style-border">
                        <input
                          type="text"
                          placeholder="Your Phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-input-inner style-border">
                        <textarea
                          placeholder="Message"
                          name="message"
                          rows={3}
                          value={formData.message}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-black mt-0 w-100 border-radius-5"
                      >
                        Submit now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
