import React from "react";
import SubServiceBanner from "../../common_components/SubServiceBanner";
import NavbarTwo from "../../components/NavbarTwo";
import FooterThree from "../../components/FooterThree";
import CtaSec from "../../common_components/CtaSec";
import OurService from "./OurService";
import TechnologySec from "./TechnologySec";
import WeWork from "../../common_components/WeWork";
import ContactSec from "../../common_components/ContactSec";
import Counter from "./Counter";
import StatsSec from "./StatsSec";

export default function DataForecasting() {
  return (
    <div>
      <NavbarTwo />
      <SubServiceBanner
        subheading="Service"
        title="Data Forecasting"
        blueText="Services"
        paragraph="Our data forecasting services help companies improve their strategy and decision-making."
      />
      <Counter/>
      <OurService/>
      <StatsSec/>
      <TechnologySec/>
      <CtaSec/>
      <WeWork/>
      <ContactSec/>
      <FooterThree />
    </div>
  );
}
