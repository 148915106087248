import React from "react";
import { Link } from "react-router-dom";
import { DataForecastingService } from "../../scripts/ServicesLists";
export default function OurService() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3">
                  <span>Get Our Top-notch</span> Data Forecasting Services
                </h2>
                <p>
                  By providing first-rate features and functionalities, data
                  forecasting services transform raw data into practical plans
                  that are appealing to an audience all over the world.
                </p>
              </div>
              <div className="main-img-wrap">
                {/*} <img
                    className='banner-animate-img banner-animate-img-1 left_image_bounce'
                    src='assets/img/banner-2/4.png'
                    alt='img'
  />*/}

                <img
                  className="main-img"
                  src="assets/img/images/data-forecasting-1.png"
                  alt="img"
                />
              </div>
            </div>
            {/*<div className="col-xl-6 col-lg-6">
            <div className="section-title ">
              <h6 className="sub-title">SERVICES</h6>
              <p>
                Whether you need a sleek landing page, a comprehensive
                e-commerce platform, or a complex content management system
                (CMS), TABS has the expertise to deliver. Here's a glimpse
                into the web development solutions we offer:
              </p>
            </div>
          </div> 
         </div>*/}
            <div className="col-lg-8 col-md-8">
              <div className="row">
                {DataForecastingService.map((data, index) => (
                  <div className="col-lg-6 col-md-6" key={index}>
                    <div className="forcasting-service-inner style-3-for-forcastingservice">
                      <div className="thumb">
                        <img src={data.img} alt="img" />
                      </div>
                      <div className="details">
                        <h5>
                          <Link to="/service-details">{data.title}</Link>
                        </h5>
                        <p>{data.des}</p>
                        {/* <Link className='read-more-text' to='/service-details'>
                  Touch More <FaPlus />
                </Link>*/}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
