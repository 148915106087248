import React from "react";
import { FullStackChooseUsList } from "../../scripts/ServicesLists";

export default function WhyChooseUs() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-10">
              <div className="section-title ">
                <h2 className="sectitle-lg">
                  <span>Why Choose</span> Full Stack Solutions from TABS?
                </h2>
              </div>
            </div>
          </div>
          {FullStackChooseUsList.map((data, index) => (
            <div
              className="row  py-5"
              style={{ borderBottom: "1px solid #d7d7d7" }}
              key={index}
            >
              <div className="col-lg-4">
                <h3 className="base-span">{data.title}</h3>
              </div>
              <div
                className="col-lg-8"
                style={{ borderLeft: "1px solid #d7d7d7" }}
              >
                <p>{data.des}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
