import React from "react";

export default function OurProcess() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative  pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="mb-5">
              <div className="section-title ">
                <h2 className="sectitle-lg">
                  How We <span>Build</span> Applications
                </h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 col-md-6">
              <h1 className="base-span process-count-heading">01</h1>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="media-for-choose-us">
                <div className="media-left me-3">
                  <img src="assets/img/icon/push.png" alt="img" />
                </div>
                <div className="media-body-for-choose-us">
                  <h4> Planning  </h4>
                  <p>
                    Having a fantastic idea is the first step to every
                    successful endeavor. As a first step, we will consult with
                    you to understand your needs and goals for the product. For
                    a precise project estimate, we choose a good technology
                    bundle and get into the nitty-gritty.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-5 reverse-row">
            <div className="col-lg-6 col-md-6">
              <div className="media-for-choose-us">
                <div className="media-left me-3">
                  <img src="assets/img/icon/web-programming.png" alt="img" />
                </div>
                <div className="media-body-for-choose-us">
                  <h4> Development </h4>
                  <p>
                    We move on to the development phase as soon as the budget
                    and the technological features are comparable to one
                    another. A demonstration is presented at the conclusion of
                    each sprint in order to guarantee that the development
                    process is aligned and transparent throughout.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <h1 className="base-span process-count-heading">02</h1>
            </div>
          </div>
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 col-md-6">
              <h1 className="base-span process-count-heading">03</h1>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="media-for-choose-us">
                <div className="media-left me-3">
                  <img src="assets/img/icon/launch.png" alt="img" />
                </div>
                <div className="media-body-for-choose-us">
                  <h4> Launch </h4>
                  <p>
                    Following exhaustive testing and the completion of all
                    necessary preparations, we will now move on to the launch
                    phase. During this phase, all of the relevant materials are
                    made available in the production domain, and the web
                    application or website is made available to the users of the
                    system.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-5 reverse-row">
            <div className="col-lg-6 col-md-6">
              <div className="media-for-choose-us">
                <div className="media-left me-3">
                  <img src="assets/img/icon/maintenance.png" alt="img" />
                </div>
                <div className="media-body-for-choose-us">
                  <h4> Maintenance of Support </h4>
                  <p>
                    Our involvement does not come to an end after the market is
                    introduced. To achieve our goal of establishing long-term
                    partnerships with our customers, we will continue to provide
                    support to them after the introduction of their products and
                    will make certain that their products are successful
                    commercially.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <h1 className="base-span process-count-heading">04</h1>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
