import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import ContactMain from "../components/ContactMain";
// import FooterOne from "../components/FooterOne";
// import NavBar from "../components/NavBar";
import NavbarTwo from "../components/NavbarTwo";
import FooterThree from "../components/FooterThree";

const Contact = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={"Contact"} />

      {/* Contact Main */}
      <ContactMain />

      {/* Footer One */}
      <FooterThree />
    </>
  );
};

export default Contact;
