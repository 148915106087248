import React from "react";

export default function Technology() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-100 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h2 className="sectitle-lg">
                  CMS Development <span>Technologies</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="row bg-gray border-radius-5 py-5 px-3 mt-3">
            <div className="col-lg-3 col-md-6 my-5">
              <div className="text-center">
                <img
                  className=""
                  src="assets/img/icon/wordpresss.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-5">
              <div className="text-center">
                <img
                  className=""
                  src="assets/img/icon/joomla.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-5">
              <div className="text-center">
                <img
                  className=""
                  src="assets/img/icon/sitecore.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-5">
              <div className="text-center">
                <img
                  className=""
                  src="assets/img/icon/wix.png"
                  alt="img"
                />
              </div>
            </div>
           {/*} <div className="col-lg-3 col-md-6 my-5">
              <div className="text-center">
                <img
                  className=""
                  src="assets/img/icon/1.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-5">
              <div className="text-center">
                <img
                  className=""
                  src="assets/img/service-icon/1.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-5">
              <div className="text-center">
                <img
                  className=""
                  src="assets/img/service-icon/1.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-5">
              <div className="text-center">
                <img
                  className=""
                  src="assets/img/service-icon/1.png"
                  alt="img"
                />
              </div>
            </div>*/}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </div>
  );
}
