import React from "react";
import NavbarTwo from "../../components/NavbarTwo";
import SubServiceBanner from "../../common_components/SubServiceBanner";
import CtaSec from "../../common_components/CtaSec";
import WeWork from "../../common_components/WeWork";
import ContactSec from "../../common_components/ContactSec";
import FooterThree from "../../components/FooterThree";
import OurServices from "./OurServices";
import DevSkills from "./DevSkills";
import WhyChooseUs from "./WhyChooseUs";
import Benefits from "./Benefits";
import CtaSection from "./CtaSection";
import Features from "./Features";
import Technology from "./Technology";

export default function CMSDevelopment() {
  return (
    <div>
      <NavbarTwo />
      <SubServiceBanner
        subheading="Service"
        title="Expand The Development of Your CMS With Our"
        blueText="Nearshore Talent"
        paragraph="Numerous active engagements are already powered by our CMS development services. We usually assign teams within two weeks, allowing you to quickly begin delivering high-caliber software."
      />
      <OurServices />
      <DevSkills />
      <CtaSection />
      <Features />
      <WhyChooseUs />
      <Benefits />
      <CtaSec />
      <Technology />
      <WeWork />
      <ContactSec />
      <FooterThree />
    </div>
  );
}
