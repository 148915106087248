import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const IndustriesHome = () => {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-relative pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-8 col-lg-10'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>INDUSTRIES</h6>
                <h2 className='title'>
                Transforming <span>Industry Challenges</span> into Opportunities
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='single-service-inner style-3'>
                <div className='details'>
                  <h5>
                    <Link to='/service-details'>Web3</Link>
                  </h5>
                  <p>
                  Revolutionize your industry by leveraging the power of blockchain technology to build groundbreaking Web3 applications.
                  </p>
                  <Link className='read-more-text' to='/service-details'>
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-service-inner style-3'>
                <div className='details'>
                  <h5>
                    <Link to='/service-details'>Logistics</Link>
                  </h5>
                  <p>
                  Optimize your supply chain and streamline operations with custom logistics solutions.
                  </p>
                  <Link className='read-more-text' to='/service-details'>
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-service-inner style-3'>
                <div className='details'>
                  <h5>
                    <Link to='/service-details'>Retail & Ecommerce</Link>
                  </h5>
                  <p>
                  Create a seamless shopping experience with engaging and user-friendly e-commerce platforms.
                  </p>
                  <Link className='read-more-text' to='/service-details'>
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-service-inner style-3'>
                <div className='details'>
                  <h5>
                    <Link to='/service-details'>On-Demand</Link>
                  </h5>
                  <p>
                  Custom solutions to meet the evolving needs of on-demand services.
                   </p>
                  <Link className='read-more-text' to='/service-details'>
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
};

export default IndustriesHome;
