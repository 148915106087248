import React from "react";
// import Marquee from "react-fast-marquee";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function AboutUsSec() {
  return (
    <div>
      {/* =============== About Area Three End ===============*/}
      <div className="about-area  pd-top-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner mb-4 mb-lg-0">
                <img
                  className="main-img"
                  src="assets/img/images/generativeai-5.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">
                  Shaping the <span>Future with Vision</span> and Mastery
                </h2>
                <p className="content mb-4">
                  At TABS, we don't just build technology, we craft the future.
                  We are a passionate collective of tech wizards. We combine
                  cutting-edge expertise with a relentless pursuit of innovation
                  to transform your ideas into groundbreaking realities.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle />
                        Bespoke Development & Design
                      </li>
                      <li>
                        <FaCheckCircle /> AI/ML Powerhouse
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> Blockchain & Web3 Architects
                      </li>
                    </ul>
                  </div>
                </div>
                <Link className="btn btn-border-base" to="/about">
                  Discover More <FaPlus />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Three End ===============*/}
    </div>
  );
}
