import React from "react";
// import AboutAreaTwo from "../components/AboutAreaTwo";
// import BannerTwo from "../components/BannerTwo";
// import BlogAreaTwo from "../components/BlogAreaTwo";
// import ContactAreaTwo from "../components/ContactAreaTwo";
// import CounterAreaThree from "../components/CounterAreaThree";
// import FooterTwo from "../components/FooterTwo";
// import NavbarTwo from "../components/NavbarTwo";
// import PricingAreaTwo from "../components/PricingAreaTwo";
// import ServiceAreaTwo from "../components/ServiceAreaTwo";
import TestimonialOne from "../components/TestimonialOne";
// import WorkProcessTwo from "../components/WorkProcessTwo";
import ContactAreaFour from "../components/ContactAreaFour";
// import AboutAreaOne from "../components/AboutAreaOne";
// import FooterFour from "../components/FooterFour";
import FooterThree from "../components/FooterThree";
import HomeBanner from "../components/HomeBanner";
// import ContactAreaThree from "../components/ContactAreaThree";
// import MainNavbar from "../components/MainNavbar";
import NavbarTwo from "../components/NavbarTwo";
import BrandAreaOne from '../components/BrandAreaOne';
// import ServiceAreaFour from '../components/ServiceAreaFour'
// import CaseStudySec from "../components/CaseStudySec";
import CtaSec from "../common_components/CtaSec";
import IndustriesHome from "../components/IndustriesHome";
import HomeCounterSec from "../components/HomeCounterSec";
import HomeAbout from "../components/HomeAbout";
import HomeService from "../components/HomeService";

const Home = () => {
  return (
    <div>
      {/* Navigation Bar Two*/}
      <NavbarTwo/>

      {/* Banner Two */}
      <HomeBanner />

        {/* Brand Area One */}
        <BrandAreaOne />

      {/* About Area Two */}
      
      <HomeAbout/>
      

     {/*} <CaseStudySec/>*/}

       {/* Service Area four */}
       <HomeService />
       <CtaSec/>
        {/* Service Area Three */}
      <IndustriesHome/>
      {/* About Area Two 
      <ServiceAreaTwo />*/}

     {/* <AboutAreaTwo />*/}
          

      {/* Pricing Area Two 
      <PricingAreaTwo />*/}

     {/* Counter Area One 
     <CounterAreaOne />*/}
    <HomeCounterSec/>
     <TestimonialOne/>
     {/* Contact Area One */}
     <ContactAreaFour />
     
      {/* Work Process Two 
      <WorkProcessTwo />*/}

      {/* Contact Area Three 
      <ContactAreaThree />*/}

      {/* Testimonial One 
      <TestimonialOne />*/}

      {/* Blog Area Two 
      <BlogAreaTwo />*/}

      {/* Footer Two */}
      <FooterThree />
    </div>
  );
};

export default Home;
