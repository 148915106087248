import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const HomeService = () => {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div
        className='service-area bg-cover pd-top-120 pd-bottom-90 pd-top-110 pd-bottom-90'
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-6 col-lg-8'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>WHAT WE OFFER</h6>
                <h2 className='title'>
                Empower your business with our <span>comprehensive suite</span> of services
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-4'>
                  <img src='assets/img/icon/18.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>Custom Development & Design</h5>
                  <p className='content mb-3'>
                  Build bespoke web applications, mobile apps, and enterprise solutions that perfectly match your vision and goals.
                  </p>
                  <Link className='read-more-text' to='/service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/16.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>AI/ML Solutions</h5>
                  <p className='content mb-3'>
                  Automate tasks, gain data-driven insights, and make smarter decisions with the power of AI and Machine Learning.
                  </p>
                  <Link className='read-more-text' to='/service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/17.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>Blockchain & Web3 Innovation</h5>
                  <p className='content mb-3'>
                  We offer secure, transparent, and decentralized solutions that transform your business operations and open up new avenues for growth.
                  </p>
                  <Link className='read-more-text' to='/service-details'>
                    Read More <FaArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
};

export default HomeService;
