import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";

export default function Counter() {
  return (
    <div>
      {/*================= counter area start {/*=================*/}
      <div className="counter-area bg-base pd-top-100 pd-bottom-100">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className=" text-center">
                <h2 className="text-white mt-3 mb-2">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter">
                          <CountUp delay={0} start={0} end={6} />+
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <h5 className="text-white">
                  Years in Custom Software Development
                </h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="text-center">
                <h2 className="text-white mt-3 mb-2">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter">
                          <CountUp delay={0} start={0} end={80} />+
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <h5 className="text-white">Software Projects Delivered</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="text-center">
                <h2 className="text-white mt-3 mb-2">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter">
                          <CountUp delay={0} start={0} end={20} />+
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <h5 className="text-white">
                  Certified Technology Professionals
                </h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="text-center">
                <h2 className="text-white mt-3 mb-2">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter">
                          <CountUp delay={0} start={0} end={96} />%
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <h5 className="text-white">Client Retention Rate</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*{/*================= counter area end {/*=================*/}
    </div>
  );
}
