import React from "react";
import { FullStackSolutionsList } from "../../scripts/ServicesLists";

export default function Solutions() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-90 pd-bottom-90">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3">
                  Full Stack <span>Solutions Encouraging</span> You to Succeed
                </h2>
              </div>
              <p className="">
                Together with your team, our Full Stack developers design the
                finest solutions for your business's requirements.
              </p>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="row">
                {FullStackSolutionsList.slice(0, 2).map((data, index) => (
                  <div className="col-lg-12 col-md-12" key={index}>
                    <div className="custom-dev-service-inner style-3-for-custom-dev">
                      <div className="details">
                        <h4 className="mb-4">{data.title}</h4>
                        <p>{data.des}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            {FullStackSolutionsList.slice(2).map((data, index) => (
              <div className="col-lg-6 col-md-6" key={index}>
                <div className="custom-dev-service-inner style-3-for-custom-dev">
                  <div className="details">
                    <h4 className="mb-4">{data.title}</h4>
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </div>
  );
}
