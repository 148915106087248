import React from "react";

export default function StatsSec() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row my-5 py-5 ">
            <div className="col-lg-8 col-md-6 align-self-center">
              <div className="details mt-3">
                <h2 style={{ color: "#246bfd" }}>
                  Development of AI-based Products
                </h2>
                <p className="mt-4">
                  With TAB Solution's generative AI development services,
                  businesses have access to a plethora of advanced tools that
                  propel them to the forefront of AI product innovation. Our
                  forte is developing robust and scalable AI applications from
                  scratch. Improved user experiences, streamlined operations,
                  and increased revenue can all result from implementing our AI
                  solutions. Collaborate with us and take use of our knowledge
                  of Generative AI Product Development to make your dreams a
                  reality.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 align-self-center">
              <div className="main-img-wrap text-center">
                <img
                  className="main-img"
                  src="assets/img/images/generativeai-6.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div className="row my-5 py-5">
            <div className="col-lg-4 col-md-6 align-self-center">
              <div className="main-img-wrap text-center">
                <img
                  className="main-img"
                  src="assets/img/images/generativeai-2.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-8 col-md-6 align-self-center">
              <div className="details ">
                <h2 style={{ color: "#246bfd" }}>Generative AI</h2>
                <p className="mt-4">
                  TABS has demonstrated an unrivaled level of skill in the field
                  of generative artificial intelligence (AI) consulting and
                  development, making it the industry leader in this field. We
                  are able to advise organizations in harnessing the power of
                  generative artificial intelligence and software development to
                  open fresh opportunities, promote creativity, and generate
                  outputs that are distinct and lifelike because our adaptable
                  team holds an in-depth grasp of the potential of these
                  technologies across a wide range of industries.
                </p>
              </div>
            </div>
          </div>
          <div className="row my-5 py-5">
            <div className="col-lg-8 col-md-6 align-self-center">
              <div className="details mt-3">
                <h2 style={{ color: "#246bfd" }}>AI Consulting</h2>
                <p className="mt-4">
                  TABS excels in artificial intelligence consulting, giving
                  unparalleled capabilities to provide professional direction
                  for navigating the complex AI landscape, conducting
                  feasibility studies, and establishing a roadmap for successful
                  deployment. TAB Solution's capabilities help it deliver expert
                  guidance. The customized approach that we use and the
                  comprehensive industry knowledge that we possess enable
                  organizations of all sizes to take advantage of the
                  transformative potential of artificial intelligence, which in
                  turn enables them to confidently achieve their strategic
                  goals.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 align-self-center">
              <div className="main-img-wrap text-center">
                <img
                  className="main-img"
                  src="assets/img/images/generativeai-3.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div className="row my-5 py-5">
            <div className="col-lg-4 col-md-6 align-self-center">
              <div className="main-img-wrap text-center">
                <img
                  className="main-img"
                  src="assets/img/images/generativeai-4.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-8 col-md-6 align-self-center">
              <div className="details mt-3">
                <h2 style={{ color: "#246bfd" }}>AI Model Development</h2>
                <p className="mt-4">
                  From initial concept to final training and fine-tuning, TABS
                  handles it all when it comes to artificial intelligence model
                  building. Using state-of-the-art methods, our team of
                  specialists creates generative models of AI that are both
                  strong and scalable. Generative model AI is fine-tuned to
                  achieve remarkable performance and accuracy by a dedication to
                  ongoing development, rigorous training, and precise attention
                  to detail. We aim to play a part in the production of
                  meaningful results.
                </p>
              </div>
            </div>
          </div>
          <div className="row my-5 py-5">
            <div className="col-lg-8 col-md-6 align-self-center">
              <div className="details mt-3">
                <h2 style={{ color: "#246bfd" }}>Architecting Solutions</h2>
                <p className="mt-4">
                  Our team is well-versed in a wide variety of AI technologies,
                  including machine learning algorithms and computer vision
                  systems, allowing us to develop cross-domain solutions that
                  are both robust and scalable. Modern technology, insights
                  derived from data, and an in-depth understanding of your
                  organization's unique requirements are all part of our
                  strategy. You can stay ahead of the curve in today's
                  fast-paced corporate world by teaming up with TABS to simplify
                  processes, gain actionable insights, and increase efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 align-self-center">
              <div className="main-img-wrap text-center">
                <img
                  className="main-img"
                  src="assets/img/images/generativeai-5.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div className="row my-5 py-5">
            <div className="col-lg-4 col-md-6 align-self-center">
              <div className="main-img-wrap text-center">
                <img
                  className="main-img"
                  src="assets/img/images/generativeai-7.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-8 col-md-6 align-self-center">
              <div className="details mt-3 ">
                <h2 style={{ color: "#246bfd" }}>Automation Solutions</h2>
                <p className="mt-4">
                  TABS offers state-of-the-art Automation Solutions that are
                  driven by AI and are made to optimize and simplify operations
                  in a wide range of sectors. The creation of smart automation
                  systems that make use of NLP and machine learning is where our
                  competence resides. You can have faith in TABS to automate
                  your operations to the next level, allowing you more time and
                  money for strategic initiatives while still achieving
                  remarkable results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
