import React from "react";
import NavbarTwo from "../../components/NavbarTwo";
import FooterThree from "../../components/FooterThree";
// import Banner from './components/Banner'
import OurServices from "./components/OurServices";
import Counter from "./components/Counter";
import CtaSec from "./components/CtaSec";
import ContributeSec from "./components/ContributeSec";
import WhyChooseUs from "./components/WhyChooseUs";
import WeWork from "./components/WeWork";
import ContactSec from "./components/ContactSec";
import SubServiceBanner from "../../common_components/SubServiceBanner";

export default function DataAnalytics() {
  return (
    <div>
      <NavbarTwo />
      {/* <Banner/>*/}
      <SubServiceBanner
        title="Data Analytics"
        subheading="Service"
        blueText="Services"
        paragraph=" Discover valuable insights from data you never knew existed."
      />
      <OurServices />
      <Counter />
      <ContributeSec />
      <CtaSec />
      <WhyChooseUs />
      <WeWork />
      <ContactSec />
      <FooterThree />
    </div>
  );
}
