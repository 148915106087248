import React from "react";
import NavbarTwo from "../../components/NavbarTwo";
import SubServiceBanner from "../../common_components/SubServiceBanner";
import WeWork from "../../common_components/WeWork";
import ContactSec from "../../common_components/ContactSec";
import FooterThree from "../../components/FooterThree";
import CtaSec from "../../common_components/CtaSec";
import OurServices from "./OurServices";
import AboutCrypto from "./AboutCrypto";
import Benefits from "./Benefits";
import Features from "./Features";
import Helping from "./Helping";

export default function CryptoDevelopment() {
  return (
    <div>
      <NavbarTwo />
      <SubServiceBanner
        subheading="Service"
        title="Crypto Trading Bot Development"
        blueText="Services"
        paragraph="Experience top-notch crypto trading bot development services that allow you to effortlessly trade, track, and automate your crypto trading activities through a versatile and user-friendly interface."
      />
      <AboutCrypto/>
      <OurServices/>
      <CtaSec />
      <Benefits/>
      <Features/>
      <Helping/>
      <WeWork />
      <ContactSec />
      <FooterThree />
    </div>
  );
}
