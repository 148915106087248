import React from "react";
// import { FaCheckCircle } from "react-icons/fa";

export default function OurServices() {
  return (
    <div>
      {/* career area start */}
      <div className="career-section pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-title">
                {/*<h6 className='sub-title'>ABOUT US</h6>*/}
                <h2 className="sectitle-lg">
                  The Power Of <span>Software</span> The Simplicity Of Solutions
                </h2>
              </div>
            </div>
            <div className="col-lg-5">
              <p className="mb-5">
                Developed with startups in mind, our Crypto Trading Bots are
                designed to be user-friendly and audience-friendly, ensuring an
                intuitive experience for all users. These bots are highly
                adaptable, capable of meeting the unique needs of various
                trading strategies and market conditions. With seamless
                integration and ready-to-go functionality, they enable startups
                to hit the ground running.
              </p>
            </div>
          </div>
          <div className="career-wrap-area">
            <nav>
              <div
                className="nav nav-tabs career-nav-tab"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id="nav-Arbitrage-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-Arbitrage"
                  type="button"
                  role="tab"
                  aria-controls="nav-Arbitrage"
                  aria-selected="true"
                >
                  Arbitrage Bot Creation
                </button>
                <button
                  className="nav-link"
                  id="nav-Sniper-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-Sniper"
                  type="button"
                  role="tab"
                  aria-controls="nav-Sniper"
                  aria-selected="false"
                >
                  Sniper Bot Development
                </button>
                <button
                  className="nav-link"
                  id="nav-Grid-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-Grid"
                  type="button"
                  role="tab"
                  aria-controls="nav-Grid"
                  aria-selected="false"
                >
                  Grid Bot Development
                </button>
                <button
                  className="nav-link"
                  id="nav-MEV-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-MEV"
                  type="button"
                  role="tab"
                  aria-controls="nav-MEV"
                  aria-selected="false"
                >
                  MEV Bot Development
                </button>
                <button
                  className="nav-link"
                  id="nav-Signal-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-Signal"
                  type="button"
                  role="tab"
                  aria-controls="nav-Signal"
                  aria-selected="false"
                >
                  Signal Bot Development
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane show active"
                id="nav-Arbitrage"
                role="tabpanel"
                aria-labelledby="nav-Arbitrage-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    {/*<div className="col-lg-5">
                      <img src="assets/img/about/16.png" alt="img" />
                    </div>*/}
                    <div className="col-lg-12 text-center">
                      <h3 className=" my-3">
                        <span className="base-span">Arbitrage</span> Bot
                        Creation
                      </h3>

                      <p className="mb-3">
                        Developing a crypto arbitrage bot entails creating a
                        pre-built algorithm or software that automates and
                        executes cryptocurrency trades according to specific
                        conditions. It performs analysis on asset prices across
                        different cryptocurrency platforms and carries out
                        trades on behalf of the user. It allows for constant
                        market monitoring, trade execution, price prediction,
                        and more.
                      </p>
                      {/*}  <div className='row'>
                       <div className='col-md-6'>
                         <ul className='single-list-inner style-check style-heading style-check mb-3'>
                           <li>
                             <FaCheckCircle /> Mistakes To Avoid to the
                           </li>
                           <li>
                             <FaCheckCircle /> Your Startup industry stan
                           </li>
                         </ul>
                       </div>
                       <div className='col-md-6'>
                         <ul className='single-list-inner style-check style-heading style-check mb-3'>
                           <li>
                             <FaCheckCircle /> Mistakes To Avoid to the
                           </li>
                           <li>
                             <FaCheckCircle /> Your Startup industry stan
                           </li>
                         </ul>
                       </div>
                     </div>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane"
                id="nav-Sniper"
                role="tabpanel"
                aria-labelledby="nav-Sniper-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    
                    <div className="col-lg-12 text-center">
                      <h3 className="my-3">
                        <span className="base-span">Sniper Bot</span>{" "}
                        Development
                      </h3>
                      <p className="mb-3">
                        An automated bot that efficiently monitors and promptly
                        purchases cryptocurrency or new tokens as soon as they
                        become available on a DEX (decentralized exchange). It
                        operates on a principle that prioritizes time and
                        assists investors in automatically monitoring the
                        cryptocurrency market and liquidating tokens following
                        the presale.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane"
                id="nav-Grid"
                role="tabpanel"
                aria-labelledby="nav-Grid-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                
                    <div className="col-lg-12 text-center">
                      <h3 className="my-3">
                        <span className="base-span">Grid Bot</span> Development
                      </h3>
                      <p className="mb-3">
                        When building a grid bot, one must first design an
                        algorithm that can automatically make trades using the
                        grid strategy. Grid trade bots are automated trading
                        programs that purchase and sell bitcoin on the market
                        according to user-defined criteria such as market
                        volume, price comparison, input, and market movement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane"
                id="nav-MEV"
                role="tabpanel"
                aria-labelledby="nav-MEV-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                   
                    <div className="col-lg-12 text-center">
                      <h3 className="my-3">
                        <span className="base-span">MEV Bot</span> Development
                      </h3>
                      <p className="mb-3">
                        In order to automatically execute transactions, find
                        lucrative possibilities, and monitor the Ethereum
                        network for fresh transactions, pre-built software
                        applications called MEV bots are utilized. To top it all
                        off, MEV bots have a plethora of features that let them
                        execute tactics like flash loans, arbitrage, and more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane "
                id="nav-Signal"
                role="tabpanel"
                aria-labelledby="nav-Signal-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                   
                    <div className="col-lg-12 text-center">
                      <h3 className="my-3">
                        <span className="base-span">Signal Bot</span>{" "}
                        Development
                      </h3>
                      <p className="mb-3">
                        Signal generator bots are a cutting-edge tool for making
                        future predictions and monitoring potential
                        cryptocurrency trades based on market data and technical
                        indicators. Once the market data has been thoroughly
                        analysed and the strategies have been specified, the
                        system will generate a buy or sell signal for the user.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* career area start */}
    </div>
  );
}
