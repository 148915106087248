import React from "react";
import NavbarTwo from "../../components/NavbarTwo";
import FooterThree from "../../components/FooterThree";
import SubServiceBanner from "../../common_components/SubServiceBanner";
import CtaSec from "../../common_components/CtaSec";
import WeWork from "../../common_components/WeWork";
import ContactSec from "../../common_components/ContactSec";
import Benefits from "./Benefits";
import OurServices from "./OurServices";
import DevelopFor from "./DevelopFor";
import TecStack from "./TecStack";
import OurProcess from "./OurProcess";

export default function WebDevelopment() {
  return (
    <div>
      {/* Navigation Bar */}
      <NavbarTwo />

      <SubServiceBanner
        subheading="Service"
        title="Web Application Development"
        blueText="Services"
        paragraph="Organizations of all shapes and sizes can reap the benefits of TABS's web application development services. Customers obtain top-notch software solutions that boost their company's profitability and make the most of their offers."
      />

      <OurServices />

      <OurProcess />

      <DevelopFor />

      <CtaSec />

      <Benefits />

      <TecStack />

      <WeWork />

      <ContactSec />

      {/* Footer One */}
      <FooterThree />
    </div>
  );
}
