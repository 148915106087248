import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/AboutUs/About";
// import Blog from "./pages/Blog";
// import BlogDetails from "./pages/BlogDetails";
// import CaseStudyDetails from "./pages/CaseStudyDetails";
import Contact from "./pages/Contact";
// import HomeFour from "./pages/HomeFour";
// import HomeOne from "./pages/HomeOne";
// import HomeThree from "./pages/HomeThree";
import Home from "./pages/Home";
// import Service from "./pages/Service";
// import ServiceDetails from "./pages/ServiceDetails";
// import Team from "./pages/Team";
// import TeamDetails from "./pages/TeamDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
// import HomeFive from "./pages/HomeFive";
import WebDevelopment from "./pages/Web_Development/WebDevelopment";
// import ArtificialIntelligence from "./pages/ArtificialIntelligence";
// import Blockchain from "./pages/Blockchain";
// import SaaS from "./pages/SaaS";
// import DigitalMarketing from "./pages/DigitalMarketing";
// import CaseStudy from "./pages/CaseStudy";
import DataAlytics from './pages/Data_Analytics/DataAnalytics'
import DataForecasting from "./pages/Data_Forecasting/DataForecasting";
import GenerativeAi from "./pages/Generative_Ai/GenerativeAi";
import SmartContractDev from "./pages/Smart_Contract_Development/SmartContractDev";
import DappDevelopment from "./pages/Dapp_Development/DappDevelopment";
import HyperLeger from "./pages/Hyperleger/HyperLeger";
import CryptoDevelopment from "./pages/Crypto_Development/CryptoDevelopment";
import CMSDevelopment from "./pages/CMS_Development/CMSDevelopment";
import CustomSoftDev from "./pages/Custom_Software_Dev/CustomSoftDev";
import FullStackDev from "./pages/Full_Stack_Development/FullStackDev";
// import CaseStudyArea from "./components/CaseStudyArea";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
       {/*} <Route exact path='/index-2' element={<HomeOne />} />
        <Route exact path='/' element={<Home />} />
        <Route exact path='/index-3' element={<HomeThree />} />
        <Route exact path='/index-4' element={<HomeFour />} />
        <Route exact path='/index-5' element={<HomeFive />} />
        <Route exact path="/web-development" element={<WebDevelopment/>}/>
        <Route exact path="/artificial-Inteligience" element={<ArtificialIntelligence/>}/>
        <Route exact path="/blockchain" element={<Blockchain/>}/>
        <Route exact path="/saas" element={<SaaS/>}/>
        <Route exact path="/digital-marketing" element={<DigitalMarketing/>}/>
        
        <Route exact path='/blog' element={<Blog />} />
        <Route exact path='/blog-details' element={<BlogDetails />} />
        <Route
          exact
          path='/case-study-details'
          element={<CaseStudyDetails />}
        />
        <Route exact path='/case-study' element={<CaseStudy />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/service' element={<Service />} />
        <Route exact path='/service-details' element={<ServiceDetails />} />
        <Route exact path='/team' element={<Team />} />
        <Route exact path='/team-details' element={<TeamDetails />} />*/}


      {/*}  <Route
        exact
        path='/case-study-details'
        element={<CaseStudyDetails />}
      />
      <Route exact path='/case-study' element={<CaseStudy />} />*/}
      <Route exact path="/web-development" element={<WebDevelopment/>}/>
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/data-analytics' element={<DataAlytics/>} />
        <Route exact path='/data-forecasting' element={<DataForecasting/>} />
        <Route exact path='/generative-ai' element={<GenerativeAi/>} />
        <Route exact path='/smartcontract-development' element={<SmartContractDev/>} />
        <Route exact path='/dapp-development' element={<DappDevelopment/>} />
        <Route exact path='/hyperleger' element={<HyperLeger/>} />
        <Route exact path='/crypto-development' element={<CryptoDevelopment/>} />
        <Route exact path='/CMS-development' element={<CMSDevelopment/>} />
        <Route exact path='/custom-development' element={<CustomSoftDev/>} />
        <Route exact path='/full-stack-development' element={<FullStackDev/>} />
      </Routes>
      <ScrollToTop smooth color='#246BFD' />
    </BrowserRouter>
  );
}

export default App;
