import React from "react";
import NavbarTwo from "../../components/NavbarTwo";
import SubServiceBanner from "../../common_components/SubServiceBanner";
import CtaSec from "../../common_components/CtaSec";
import WeWork from "../../common_components/WeWork";
import FooterThree from "../../components/FooterThree";
import ContactSec from "../../common_components/ContactSec";
import OurServices from "./OurServices";
import Techniques from "./Techniques";
import Method from "./Method";
import DevServices from "./DevServices";
import Counter from "./Counter";
import Features from "./Features";
import TechnologyStack from "../../common_components/TechnologyStack";
import { CustomDevTechnologyStackList } from "../../scripts/ServicesLists";

export default function CustomSoftDev() {
  return (
    <div>
      <NavbarTwo />
      <SubServiceBanner
        subheading="Service"
        title="Custom Software Development"
        blueText="Services"
        paragraph="With our specialized, unique software solutions that may spark creativity, you can enable your company to grow beyond all expectations."
      />
      <OurServices />
      <DevServices />
      <Method />
      <Counter />
      <Features />
      <Techniques />
      <TechnologyStack
        listname={CustomDevTechnologyStackList}
        bluetext="Expertise"
        title="in Frameworks"
      />
      <CtaSec />
      <WeWork />
      <ContactSec />
      <FooterThree />
    </div>
  );
}
