import React from "react";

export default function ContributeSec() {
  return (
    <div>
      <div
        className="bg-relative bg-cover pd-bottom-120"
        style={{
          backgroundImage: 'url("./assets/img/banner/databanner3.jpg")',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 pd-bottom-80">
              <div className="align-self-center bg-gray px-5 py-5 margin-minus-top-60 border-radius-5 ">
                <div className="section-title">
                  {/*<h6 className='sub-title'>GET IN TOUCH</h6>*/}
                  <h2 className="title mb-5">
                    Our Data Consultants Contribution to Your{" "}
                    <span>Achievement</span>
                  </h2>
                  <p>
                    In a variety of formats, we provide unparalleled
                    experiences. The technological solution is secondary to our
                    consultants' primary objective of assisting customers in
                    delivering business value through analytics.<br/> 
                    Our commitment is to ensure that all members of the client's team have the
                    necessary information by educating stakeholders and
                    streamlining our knowledge to facilitate the adoption of our
                    services. In this manner, we ensure the sustainability and
                    longevity of our solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
