import React from 'react'
import { Link } from 'react-router-dom'
import { FaPlus } from "react-icons/fa";


export default function HireNowSec() {
  return (
    <div>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className="about-area pd-top-120 pd-bottom-80 bg-gray">
        <div className="container">
          <div className="row">
            
            <div
              className="col-lg-8 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title ">
                <h2 className="sectitle-lg ">
                We Know <span>Hyperledger Development Tools</span>
                </h2>
                <p className="content mt-5">
                  We utilize the Go programming language for a variety of chain
                  code components within Hyperledger Fabric. Our team at TABS is
                  well-versed in multiple programming languages, including
                  Python and Rust. This expertise allows us to create the server
                  component of Hyperledger Indy, known as the Indy node, as well
                  as the Indy SDK. Our blockchain professionals are highly
                  skilled in Java, and Hyperledger Besu is an open-source
                  Ethereum client developed under the Apache 2.0 license.
                  Applications that operate on top of the Sawtooth platform can
                  be created using REST API and SDKs in various languages such
                  as Python, C++, Go, Java, JavaScript, and Rust. In addition,
                  Solidity enables the creation of smart contracts that can be
                  utilized with the Seth transaction family.
                </p>
                <Link
                  className='btn btn-border-base'
                  data-aos='fade-right'
                  data-aos-delay='300'
                  data-aos-duration='1500'
                  to='/about'
                >
                  Hire Now <FaPlus />
                </Link>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="about-thumb-inner  me-xl-5 "
                data-aos="fade-left"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="main-img"
                  src="assets/img/images/hyperladger-1.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </div>
  )
}
