import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function CtaSec() {
  return (
    <div>
      <div className="counter-area bg-relative pd-top-120 pd-bottom-120">
        <div className="cta-sec">
          <div className="container">
            <div
              className="cta-sec-inner bg-cover"
              style={{ backgroundImage: 'url("./assets/img/bg/6.png")' }}
            >
              <div className="row">
                <div className="col-lg-8">
                  <h2 className="mb-lg-0 mb-3">
                    Optimize Your Business Decisions by Unlocking The Potential
                    of Your Data!
                  </h2>
                </div>
                <div className="col-lg-4 align-self-center text-end">
                  <Link
                    className="btn btn-border-base1"
                    data-aos="fade-right"
                    data-aos-delay="300"
                    data-aos-duration="1500"
                    to="/about"
                  >
                    Contact Us <FaAngleRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
