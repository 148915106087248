import React from "react";
import { CustomSoftServicesList } from "../../scripts/ServicesLists";

export default function Techniques() {
  return (
    <div>
      {/*==================== Faq area start ====================*/}
      <div className="faq-area   pd-bottom-120 bg-gray">
        <div className="container">
          <div className="row pd-top-120">
            <div
              className=" col-lg-12 col-md-6"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0 mb-5">
                <h2 className="sectitle-lg">
                  <span>Top Techniques</span> for Developing Custom Software
                  Creating
                </h2>
              </div>
              <div
                className="accordion custom-soft-accordion-inner style-2 custom-soft-accordion-icon-right mt-3"
                id="accordionExample"
              >
                {CustomSoftServicesList.map((data, index) => (
                  <div className="custom-soft-accordion-item" key={index}>
                    <h2
                      className="custom-soft-accordion-header"
                      id={data.headingid}
                    >
                      <button
                        className="accordion-button collapsed mb-5"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={data.collapseidcall}
                        aria-expanded="false"
                        aria-controls={data.collapseid}
                      >
                        {data.heading}
                      </button>
                    </h2>
                    <div
                      id={data.collapseid}
                      className="accordion-collapse collapse"
                      aria-labelledby={data.headingid}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="custom-soft-accordion-body">
                        <div className="row">
                          {data.childdata.map((child_data, index) => (
                            <div className="col-lg-6" key={index}>
                              <div className="px-3 py-3">
                                <h5 className="mb-2">{child_data.title}</h5>
                                <p>{child_data.des}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/*} <div
              className="col-lg-3 col-md-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <img
                className="main-img text-center"
                src="assets/img/about/4.png"
                alt="img"
              />
            </div>*/}
          </div>
        </div>
      </div>
      {/* ==================== Faq area end ====================*/}
    </div>
  );
}
