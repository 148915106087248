import React from "react";
import { Link } from "react-router-dom";
// import { FaPlus } from "react-icons/fa";
import { DataAnalyticsServicesList } from "../../../scripts/ServicesLists";

export default function OurServices() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div
        className="service-area bg-relative pd-top-120 pd-bottom-90 bg-cover"
        style={{
          backgroundImage: 'url("./assets/img/banner/databanner1blur.jpg")',
          backgroundAttachment: "fixed",
        }}
      >
        <div className="container custom-container">
          <div className="row">
            <div className="">
              <div className="section-title ">
                <h2 className="sectitle-lg text-white text-center">
                  Data Security, Access and Leveraging for Company Growth
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {DataAnalyticsServicesList.map((data, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="single-service-inner style-3-for-dataservice">
                  <div className="thumb">
                    <img src={data.img} alt="img" />
                  </div>
                  <div className="details">
                    <h5>
                      <Link to="/service-details">{data.title}</Link>
                    </h5>
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
