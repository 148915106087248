import React from "react";
import { SmartContractBusinessList } from "../../scripts/ServicesLists";

export default function ForBusiness() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative  pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row mb-5">
            <div className="mb-5">
              <div className="section-title ">
                <h2 className="sectitle-lg">
                  FOR YOUR <span>BUSINESS</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {SmartContractBusinessList.map((data, index) => (
              <div className="col-lg-4 col-md-6 mb-5" key={index}>
                <div className="media-for-choose-us mb-5">
                  <div
                    className="media-left"
                    style={{ marginTop: "-40px", marginRight: "-60px" }}
                  >
                    <img
                      className="left_image_bounce"
                      style={{ zIndex: "-1", position: "relative" }}
                      src={data.img}
                      alt="img"
                    />
                  </div>
                  <div className="media-body-for-choose-us">
                    <h4>{data.title}</h4>
                    <p>{data.des} </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </div>
  );
}
