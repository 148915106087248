import React from "react";

export default function SvgArrowLeft() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="662"
        height="160"
        viewBox="0 0 542 159"
      >
        <g fill="none" fill-rule="evenodd">
          <path
            fill-rule="nonzero"
            stroke="#246BFD"
            stroke-dasharray="10,5"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M16.026 129c5.676-52.921 90.96-70.707 255.852-53.356 158.93 16.723 244.223.63 255.879-48.28"
          />
          <path fill="#246BFD" d="M15 129l15 15-15 15-15-15z" />
          <circle cx="527" cy="15" r="15" fill="#246BFD" fill-rule="nonzero" />
        </g>
      </svg>
    </div>
  );
}
