import React from "react";

export default function TecStack() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-10">
              <div className="section-title ">
                <h2 className="sectitle-lg">
                  Web Application Development <span>Tech Stack</span>
                </h2>
              </div>
              <p>
                Our web apps development services are of the greatest quality
                since we use cutting-edge technologies. We assist businesses in
                boosting their bottom lines in this manner. To get the most out
                of their USP, TABS offers solutions that are strong, adaptable,
                and extensible.
              </p>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-lg-4">
              <h3 className="base-span">Back-end</h3>
            </div>
            <div className="col-lg-8">
              <div className="web-tech-stack">
                <h6>Java</h6>
                <h6>NODE.JS</h6>
                <h6>PYTHON </h6>
                <h6>PHP</h6>
              </div>
            </div>
          </div>
          <div className="row  py-5" style={{ borderTop: "1px solid #d7d7d7" }}>
            <div className="col-lg-4">
              <h3 className="base-span">Front-end</h3>
            </div>
            <div className="col-lg-8">
              <div className="web-tech-stack">
                <h6>JAVASCRIPT </h6>
                <h6>REACT </h6>
                <h6>ANGULAR </h6>
                <h6>VUE </h6>
              </div>
            </div>
          </div>
          {/*<div className="row  py-5" style={{ borderTop: "1px solid #d7d7d7" }}>
            <div className="col-lg-4">
              <h3 className="base-span">Mobile</h3>
            </div>
            <div className="col-lg-8">
              <div className="web-tech-stack">
                <h6>SWIFT </h6>
                <h6>KOTLIN </h6>
                <h6>FLUTTER </h6>
                <h6>OBJECTIVE-C</h6>
                <h6>JAVA</h6>
              </div>
            </div>
          </div>*/}
          <div className="row  py-5" style={{ borderTop: "1px solid #d7d7d7" }}>
            <div className="col-lg-4">
              <h3 className="base-span">Databases</h3>
            </div>
            <div className="col-lg-8">
              <div className="web-tech-stack">
                <h6>POSTGRESQL </h6>
                <h6>MYSQL </h6>
                <h6>REDIS </h6>
                <h6>ELASTICSEARCH</h6>
              </div>
            </div>
          </div>
          <div className="row  py-5" style={{ borderTop: "1px solid #d7d7d7" }}>
            <div className="col-lg-4">
              <h3 className="base-span">Infrastructure</h3>
            </div>
            <div className="col-lg-8">
              <div className="web-tech-stack">
                <h6>HEROKU </h6>
                <h6>AWS </h6>
                <h6>GCP </h6>
                <h6>AZURE </h6>
                <h6>DIGITAL OCEAN</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
