import React from "react";
// import { Link } from "react-router-dom";
// import { FaAngleRight } from "react-icons/fa";
export default function WeWork() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90 bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="section-title ">
                <h2 className="sectitle-lg">
                  How We <span>Work Together</span>
                </h2>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="section-title ">
                {/* <h6 className="sub-title">SERVICES</h6>*/}
                <p>
                  Whether you require data science consultation or a completely
                  managed staff of Machine Learning Experts, we can meet all of
                  your requirements. On top of that, we may merge service models
                  as needed and guide you through the process of switching
                  between them. For a limited time, we offer project managers,
                  product owners, and business analysts as non-billable team
                  members during the transition from staff augmentation to a
                  committed team.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="single-service-inner style-3-for-wework">
                <div className="thumb">
                  <img src="assets/img/service-icon/1.png" alt="img" />
                </div>

                <div className="details">
                  <h5>Staff Augmentation</h5>
                  <p>
                    Allow TABS to handpick exceptional data science
                    professionals for your project. With our expertise, we can
                    help you address any skill gaps in your in-house software
                    development team and effectively scale our engagement.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-service-inner style-3-for-wework">
                <div className="thumb">
                  <img src="assets/img/service-icon/1.png" alt="img" />
                </div>

                <div className="details">
                  <h5>Dedicated Team</h5>
                  <p>
                    Gather a group of data scientists and engineers that are
                    well-versed in all the areas your project requires. Let TABS
                    take care of team management so you can concentrate on your
                    company ideas.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-service-inner style-3-for-wework">
                <div className="thumb">
                  <img src="assets/img/service-icon/1.png" alt="img" />
                </div>

                <div className="details">
                  <h5>Fixed-Cost Project</h5>
                  <p>
                    Allow TABS to guide your project from beginning to end,
                    ensuring that it stays within the agreed scope, budget, and
                    timeline. We assume the responsibility for the final outcome
                    while you fine-tune your project vision.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-service-inner style-3-for-wework">
                <div className="thumb">
                  <img src="assets/img/service-icon/1.png" alt="img" />
                </div>

                <div className="details">
                  <h5>AI Consulting</h5>
                  <p>
                    Get expert guidance from our experienced leaders on how to
                    successfully implement AI in your organization. We offer a
                    comprehensive plan that outlines clear and actionable steps
                    to achieve your desired business objectives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
