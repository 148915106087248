import React from "react";
import { Link } from "react-router-dom";

export default function OurServices() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-6">
              <div className="section-title ">
                <h2 className="sectitle-lg">
                  Personalized Web Application <span>Development Services</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner style-3">
                <div className="thumb">
                  <img src="assets/img/icon/saas.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/service-details">
                      SaaS Apps Development Services
                    </Link>
                  </h5>
                  <p>
                    In order to improve customer service, our SaaS specialists
                    will assist you in developing secure, multi-tenant SaaS
                    systems.
                  </p>
                  {/* <Link className='read-more-text' to='/service-details'>
                  Touch More <FaPlus />
                </Link>*/}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner style-3">
                <div className="thumb">
                  <img src="assets/img/icon/frontend.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/service-details">
                      Front end Development Services
                    </Link>
                  </h5>
                  <p>
                    Use the modern, reusable, and component-driven frameworks of
                    React.js, Angular.js, and Vue.js to create single-page web
                    applications.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner style-3">
                <div className="thumb">
                  <img src="assets/img/icon/wordpress.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/service-details">
                      WordPress Web Application Development Services
                    </Link>
                  </h5>
                  <p>
                    Our WordPress web app development services provide complex
                    corporate goods and services. The solution can also include
                    plugins or third-party technologies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner style-3">
                <div className="thumb">
                  <img src="assets/img/icon/backend-coding.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/service-details">
                      Backend Development Services
                    </Link>
                  </h5>
                  <p>
                    With scalability, integration flexibility, advanced data
                    structures, and state-of-the-art security in mind, our
                    software architects craft robust backends.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner style-3">
                <div className="thumb">
                  <img src="assets/img/icon/digital-services.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/service-details">
                      E-commerce Web Apps Development Services
                    </Link>
                  </h5>
                  <p>
                    We assist you in developing B2B eCommerce portals and
                    e-shops using Shopify, Magento, and bespoke frameworks to
                    enhance your expansion.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner style-3">
                <div className="thumb">
                  <img src="assets/img/icon/full-stack.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/service-details">
                      Custom Web Apps Development for Enterprises
                    </Link>
                  </h5>
                  <p>
                    Online applications, such as systems for managing
                    inventories, transportation, education, and processes, can
                    improve efficiency throughout a business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
