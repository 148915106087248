import React from "react";
import { WhyChooseCMSList } from "../../scripts/ServicesLists";

export default function WhyChooseUs() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3 text-center">
                  You've Found What <span>You're Looking</span> for in{" "}
                  <span>CMS Development</span>
                </h2>
              </div>
              <p className="text-center">
                Here is why businesses choose TABS as their partner:
              </p>
            </div>
          </div>
          <div className="row mt-5">
            {WhyChooseCMSList.map((data, index) => (
              <div className="col-lg-4 col-md-4" key={index}>
                <div className="dapp-we-offer-service-inner style-3-for-dapp-we-offer">
                  <div className="details">
                    <img className="my-4" src={data.img} alt="img" />
                    <h4 className="mb-3">{data.title}</h4>
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </div>
  );
}
