import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
export default function Counter() {
  return (
    <div>
      {/*================= counter area start {/*=================*/}
      <div className='counter-area bg-base pd-top-60 pd-bottom-60'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className=' text-center'>
                <h2 className='text-white mt-3 mb-2'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp delay={0} start={0} end={6} />+
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white'>Years of Experience</p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='text-center'>
                <h2 className='text-white mt-3 mb-2'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp delay={0} start={0} end={15} />+
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white'>AI Developers</p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='text-center'>
                <h2 className='text-white mt-3 mb-2'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp delay={0} start={0} end={97} />%
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white'>Success Ratio</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*{/*================= counter area end {/*=================*/}
    </div>
  )
}
