import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CustomSoftServiceList } from "../../scripts/ServicesLists";

export default function OurServices() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-90 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3">
                  When to <span>Choose Our Custom </span> Software Development
                  Services
                </h2>
              </div>
              <p className="">
                In a timely manner, and paying close attention to quality,
                return on investment, and your users, we offer robust software
                solutions to businesses of all sizes.
              </p>
              <p>
                {" "}
                Success can be attained with our specially designed software
                solutions, made to meet your company's requirements. Consider
                our software development services at the following times:
              </p>
              <Link
                className="btn btn-border-base my-5"
                data-aos="fade"
                data-aos-delay="300"
                data-aos-duration="1500"
                to="/about"
              >
                Contact Us <FaAngleRight />
              </Link>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="row">
                {CustomSoftServiceList.map((data, index) => (
                  <div className="col-lg-12 col-md-12" key={index}>
                    <div className="custom-dev-service-inner style-3-for-custom-dev">
                      <div className="details">
                        <h4 className="mb-4">{data.title}</h4>
                        <p>{data.des}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </div>
  );
}
