import React from "react";

const HomeAbout = () => {
  return (
    <div>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
               {/*} <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/3.png'
                  alt='img'
  />*/}
                <img
                  className='animate-img-2 left_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                />
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                <img
                  className='main-img'
                  src='assets/img/common/4002785.jpg'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h6 className='sub-title'>ABOUT US</h6>
                <h2 className='title'>
                Passion for <span>Excellence, Commitment</span> to Innovation
                </h2>
                <p className='content mb-4 mb-xl-5'>
                At TABS, our passion drives us to deliver Bespoke Web Development & Design, cutting-edge AI/ML solutions, and pioneering Blockchain technologies. Our team of seasoned experts, spread across the globe, ensures that we bring your vision to life with unparalleled precision and creativity. 
                </p>
                <p className='content mb-4 mb-xl-5'>
                Expect exceptional results, delivered with clear communication and a focus on your unique needs. Ready to craft something extraordinary? Let's discuss your project and embark on this journey of innovation together.                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </div>
  );
};

export default HomeAbout;
