import React from "react";
import { DappDevChooseUsList } from "../../scripts/ServicesLists";
export default function WhyChoose() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3 text-center">
                  <span>Why Choose Us</span> for DApps Development Services?
                </h2>
                <p className="text-center">
                  Instead of daydreaming about creating your own decentralized
                  app, employ our skilled developers and make it a reality. Our
                  expert DApp consulting services make us your best bet for any
                  and all DApp development requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {DappDevChooseUsList.map((data, index) => (
              <div className="col-lg-6 col-md-6 my-4" key={index}>
                <div className="dapp-dev-service-inner style-3-for-dapp-dev">
                  <div className="details">
                    <h4 className="mb-4">{data.title}</h4>
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </div>
  );
}
