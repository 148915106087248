import React from "react";
import NavbarTwo from "../../components/NavbarTwo";
import FooterThree from "../../components/FooterThree";
import SubServiceBanner from "../../common_components/SubServiceBanner";
import CtaSec from "../../common_components/CtaSec";
import WeWork from "../../common_components/WeWork";
import ContactSec from "../../common_components/ContactSec";
import Counter from "./Counter";
import OurServices from "./OurServices";
import OurMethod from "./OurMethod";
import ForBusiness from "./ForBusiness";

export default function SmartContractDev() {
  return (
    <div>
      <NavbarTwo />
      <SubServiceBanner
        subheading="Opt for an Open Method of Conducting Business"
        title="Smart Contract Development"
        blueText="Services"
        paragraph="Developing the most trustworthy and secure smart contracts on several blockchain systems including Ethereum, EDS, Hyperledger, TRON, and others."

      />
      <Counter/>
      <OurServices/>
      <OurMethod/>
      <CtaSec />
      <ForBusiness/>
      <WeWork/>
      <ContactSec/>
      <FooterThree />
    </div>
  );
}
