import React from "react";
import Slider from "react-slick";
import {DataForecastingStats} from "../../scripts/ServicesLists";

export default function StatsSec() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className="about-area pd-top-120 pd-bottom-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="about-thumb-inner  me-xl-5 "
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="main-img"
                  src="assets/img/images/data-forecasting-2.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-7 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                {/* <h6 className='sub-title'>ABOUT US</h6>*/}
                <h2 className="title">
                  Personalized <span>AI Solutions</span> to Unlock AI's
                  Potential for All
                </h2>
                <p className="content mb-4 mb-xl-5">
                  With our expertise in custom artificial intelligence
                  development solutions, we cater to the needs of multinational
                  businesses as an independent AI development company. Our team
                  of skilled developers harnesses cutting-edge artificial
                  intelligence capabilities. With a keen eye on the latest tech
                  trends, we strive to create industry-specific AI solutions for
                  our diverse range of clients.
                </p>
                <div className="row">
                  <Slider {...settings}>
                  {DataForecastingStats.map((data,index) => (
                    <div className="col-md-6" key={index}>
                      <div className="single-about-inner p-3">
                        <div className="thumb mb-3">
                          <img src={data.img} alt="img" />
                        </div>
                        <div className="details">
                          <h5>{data.title}</h5>
                          <p>
                            {data.des}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </div>
  );
}
