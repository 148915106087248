import React from "react";
import NavbarTwo from "../../components/NavbarTwo";
import SubServiceBanner from "../../common_components/SubServiceBanner";
import CtaSec from "../../common_components/CtaSec";
import WeWork from "../../common_components/WeWork";
import ContactSec from "../../common_components/ContactSec";
import FooterThree from "../../components/FooterThree";
import WhyChooseUs from "./WhyChooseUs";
import OurServices from "./OurServices";
import Solutions from "./Solutions";
import CraftingCta from "./CraftingCta";
import CtaSection from "./CtaSection";
import Features from "./Features";


export default function FullStackDev() {
  return (
    <div>
      <NavbarTwo />
      <SubServiceBanner
        subheading="Service"
        title="Full Stack Development "
        blueText="Services"
        paragraph="Our excellent full stack development firm provides a multitude of services, such as database programming, micro-services, front-end and back-end development, UI design, API integration, hosting, and more. Create top-notch cloud, mobile, and online applications by utilizing our outcome-driven full stack development services."
      />
      <OurServices />
      <Solutions />
      <CtaSection />
      <Features />
      <CraftingCta />
      <WhyChooseUs />
      <CtaSec />
      <WeWork />
      <ContactSec />
      <FooterThree />
    </div>
  );
}
