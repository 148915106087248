import React from "react";
import {
  FaChevronRight,
  FaFacebookF,
  // FaInstagram,
  // FaTwitter,
  // FaYoutube,
  FaLinkedin 
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterThree = () => {
  return (
    <div>
      {/* ================== Footer Two Start ==================*/}
      <footer className='footer-area bg-black mt-0 pd-top-100'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-3 col-lg-4 col-md-6'>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src='assets/img/tablogo-03c.png' alt='img' />
                </div>
                <div className='details'>
                  <p>
                    Melbourne is simply is dumiomy is text Lorem Ipsum is simply
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-4 offset-xl-2 col-md-6 ps-xl-5'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Our Service</h4>
                <ul>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight />Custom Development & Design
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> AI/ML Solutions
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Blockchain & Web3 Innovation
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-xl-2 col-lg-4 offset-xl-1 col-md-6'>
              <div className='widget widget_about'>
                <h4 className='widget-title'>Get In Touch</h4>
                <div className='details'>
                  <ul className='social-media'>
                    <li>
                      <a href='/'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='/'>
                        <FaLinkedin />
                      </a>
                    </li>
                   {/*} <li>
                      <a href='/'>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='/'>
                        <FaYoutube />
                      </a>
                    </li>*/}
                  </ul>
                  {/*<div className='subscribe mt-4'>
                    <input type='text' placeholder='E-mail' />
                    <button>
                      <FaChevronRight />
                    </button>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom bg-gray-black'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>© TECH&BLOCK 2024 | All Rights Reserved</p>
              </div>
              <div className='col-md-6 text-lg-end'>
                <a href='/'>Trams &amp; Condition</a>
                <a href='/'>Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </div>
  );
};

export default FooterThree;
