import React from 'react'

export default function OurMission() {
  return (
    <div>
      {/* ================== AboutAreaSix start  ==================*/}
      <div className='about-area  pd-top-120'>
        <div className='container custom-container'>
          <div className=' z-index-2 border-radius-5 bg-blue' style={{padding:"120px 80px"}}>
            <div className='row'>
              <div
                className='col-lg-12'
                data-aos='fade-left'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <div className='section-title mt-5 mt-lg-0 mb-0'>
                <h6 className='sub-title'>Our Mission</h6>
                  <h2 className='title text-white'>Innovate, Grow, and Succeed with TABS</h2>
                  <p className='content text-white'>
                  At TABS, our primary goal is to deliver top-notch quality and ensure client satisfaction. We believe in building long-lasting relationships with our clients by consistently delivering exceptional results that align with their vision and objectives. Our commitment to quality and customer-centric approach sets us apart, making us a trusted partner for businesses looking to innovate and grow.                  
                  </p>
                  <p className='small-title text-white'>
                  Join us at TABS and experience the future of technology with a partner you can trust. Let’s create something extraordinary together.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== AboutAreaSix End  ==================*/}
    </div>
  )
}
