import React from "react";
import { FaRegCircle } from "react-icons/fa";

export default function TechnologySec() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div
        className="service-area bg-relative bg-cover pd-top-120 pd-bottom-90"
        style={{
          backgroundImage: 'url("./assets/img/bg/8.png")',
        }}
      >
        <div className="container ">
          <div className="row">
            <div className="mb-5">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-5">
                  Technologies We Use in <span>AI Development</span>
                </h2>
                <p>
                  Own cutting-edge solutions by tapping into the technical
                  knowledge of a leading AI development firm.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-6 mb-5">
              <div className="row">
                <div className="col-6">
                  {/*Frameworks Heading */}
                  <div className="mb-3">
                    <h4 style={{ color: "#246bfd" }}>Frameworks</h4>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Tensorflow</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>PyTorch</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>MXNet</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Nvidia</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Caffe</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Caffe2</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Chainer</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Theano</h6>
                    </div>
                  </div>
                  {/*Algorithms Heading */}
                  <div className="mb-3 mt-5">
                    <h4 style={{ color: "#246bfd" }}>Algorithms</h4>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Supervised/ Unsupervised Learning</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Clustering</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Metric Learning</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Fewshot Learning</h6>
                    </div>
                  </div>
                  {/*Concepts Heading */}
                  <div className="mb-3 mt-5">
                    <h4 style={{ color: "#246bfd" }}>Concepts</h4>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Supervised/ unsupervised learning</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>
                        Clustering (density-based, Hierarchical, partitioning)
                      </h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Metric learning</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Few-shot learning</h6>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  {/*NLP Heading */}
                  <div className="mb-3">
                    <h4 style={{ color: "#246bfd" }}>NLP Neural Networks</h4>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>CNN</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>RNN</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Representation Learning</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Manifold Learning</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Variational Autoencoders</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Bayesian Network</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Autoregressive Networks</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Autoencoders (VAE, DAE SAE, etc.)</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Generative adversarial networks (GANs)</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Deep Q-Network (DQN)</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Feedforward Neural Network</h6>
                    </div>
                  </div>

                  {/*Module / Toolkits Heading */}
                  <div className="mb-3 mt-4">
                    <h4 style={{ color: "#246bfd" }}>Module / Toolkits</h4>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Core ML</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Microsoft Cognitive Toolkit</h6>
                    </div>
                  </div>
                  <div className="media-for-technology-sec mb-2">
                    <div className="me-2">
                      <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                        <FaRegCircle />
                      </h6>
                    </div>
                    <div className="media-body-for-technology-sec">
                      <h6>Kurento's computer vision module</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
              {/*Libraries Heading */}
              <div className="mb-3 ">
                <h4 style={{ color: "#246bfd" }}>Libraries</h4>
              </div>
              <div className="media-for-technology-sec mb-2">
                <div className="me-2">
                  <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                    <FaRegCircle />
                  </h6>
                </div>
                <div className="media-body-for-technology-sec">
                  <h6>Sonnet</h6>
                </div>
              </div>
              <div className="media-for-technology-sec mb-2">
                <div className="me-2">
                  <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                    <FaRegCircle />
                  </h6>
                </div>
                <div className="media-body-for-technology-sec">
                  <h6>Tensorlfow probability</h6>
                </div>
              </div>
              <div className="media-for-technology-sec mb-2">
                <div className="me-2">
                  <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                    <FaRegCircle />
                  </h6>
                </div>
                <div className="media-body-for-technology-sec">
                  <h6>Tensor2Tensor</h6>
                </div>
              </div>
              <div className="media-for-technology-sec mb-2">
                <div className="me-2">
                  <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                    <FaRegCircle />
                  </h6>
                </div>
                <div className="media-body-for-technology-sec">
                  <h6>tf-slim</h6>
                </div>
              </div>
              <div className="media-for-technology-sec mb-2">
                <div className="me-2">
                  <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                    <FaRegCircle />
                  </h6>
                </div>
                <div className="media-body-for-technology-sec">
                  <h6>OpenNN</h6>
                </div>
              </div>
              <div className="media-for-technology-sec mb-2">
                <div className="me-2">
                  <h6 style={{ color: "#646464", marginTop: "-1px" }}>
                    <FaRegCircle />
                  </h6>
                </div>
                <div className="media-body-for-technology-sec">
                  <h6>Neuroph</h6>
                </div>
              </div>

              <div className="main-img-wrap mt-5">
                <img
                  className="main-img"
                  src="assets/img/images/data-forecasting-3.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
