import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";

export default function AboutCrypto() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container custom-container">
          {/*}<div className="row">
            <div className="col-lg-8">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3">
                  <span>Crypto</span>  Trading Bot
                </h2>
              </div>
            </div>
          </div>*/}
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3">
                  <span>Crypto</span> Trading Bot
                </h2>
              </div>
              <div className="main-img-wrap">
                {/*} <img
                    className='banner-animate-img banner-animate-img-1 left_image_bounce'
                    src='assets/img/banner-2/4.png'
                    alt='img'
  />*/}
                <img
                  className="main-img self-align-center"
                  src="assets/img/images/crypto-1.png"
                  alt="img"
                />
              </div>
            </div>
            {/*<div className="col-xl-6 col-lg-6">
            <div className="section-title ">
              <h6 className="sub-title">SERVICES</h6>
              <p>
                Whether you need a sleek landing page, a comprehensive
                e-commerce platform, or a complex content management system
                (CMS), TABS has the expertise to deliver. Here's a glimpse
                into the web development solutions we offer:
              </p>
            </div>
          </div> 
         </div>*/}
            <div className="col-lg-7 col-md-6">
              <p className="">
                Crypto trading bots are software programmes designed to automate
                the process of buying and selling cryptocurrencies. They operate
                based on specific data or information. The primary goal of
                Cryptocurrency trading bots is to generate profits from Crypto
                trading with minimal manual effort. Typically, this software
                tool automates Cryptocurrency trading to maximise ROI. Trading
                bots for cryptocurrency provide a faster and more effective
                trading experience by thoroughly analysing market opportunities.
                One way to achieve mainstream status for cryptocurrency is
                through various factors, such as utilizing a crypto trading bot.
                The crypto trading bot is designed with pre-established
                strategies and rules that take into account market trends,
                indicators, and other factors. It closely monitors the
                Cryptocurrency market, analyzing patterns, indicators, and
                signals that align with its pre-programmed instructions, and
                then executes trades accordingly.
              </p>
              <div className="row bg-base border-radius-5 py-5 my-5">
                <div className="col-lg-4 col-md-6">
                  <div className=" text-center">
                    <h2 className="text-white mt-3 mb-2">
                      <TrackVisibility once>
                        {({ isVisible }) =>
                          isVisible && (
                            <span className="counter">
                              <CountUp delay={0} start={0} end={10} />+
                            </span>
                          )
                        }
                      </TrackVisibility>
                    </h2>
                    <p className="text-white">Country</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="text-center">
                    <h2 className="text-white mt-3 mb-2">
                      <TrackVisibility once>
                        {({ isVisible }) =>
                          isVisible && (
                            <span className="counter">
                              <CountUp delay={0} start={0} end={20} />+
                            </span>
                          )
                        }
                      </TrackVisibility>
                    </h2>
                    <p className="text-white">Projects Delivered</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="text-center">
                    <h2 className="text-white mt-3 mb-2">
                      <TrackVisibility once>
                        {({ isVisible }) =>
                          isVisible && (
                            <span className="counter">
                              <CountUp delay={0} start={0} end={25} />+
                            </span>
                          )
                        }
                      </TrackVisibility>
                    </h2>
                    <p className="text-white">Total Projects</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
