import React from "react";
import NavbarTwo from "../../components/NavbarTwo";
import CtaSec from "../../common_components/CtaSec";
import WeWork from "../../common_components/WeWork";
import ContactSec from "../../common_components/ContactSec";
import FooterThree from "../../components/FooterThree";
import SubServiceBanner from "../../common_components/SubServiceBanner";
import About from "./About";
import OurServices from "./OurServices";
import WeOffer from "./WeOffer";
import WhyChoose from "./WhyChoose";
import TechnologyStack from "../../common_components/TechnologyStack";
import { DappDevTechStackList } from "../../scripts/ServicesLists";

export default function DappDevelopment() {
  return (
    <div>
      <NavbarTwo />
      <SubServiceBanner
        subheading="Service"
        title="Dapp Development"
        blueText="Services"
        paragraph="Decentralized applications (dapps) developed using TABS are secure, trustless, fast, scalable, and stable, and they work in any industry or vertical.Expert in developing decentralized application (dapp) backend code, user interface design and development, and integrating technology into organizational objectives and procedures. Companies can benefit from TABS's decentralized applications, which stimulate expansion."
      />
      <About />
      <OurServices />
      <CtaSec />
      <WeOffer />
      <TechnologyStack
        listname={DappDevTechStackList}
        bluetext="Expertise"
        title="in Frameworks"
      />
      <WhyChoose />
      <WeWork />
      <ContactSec />
      <FooterThree />
    </div>
  );
}
