import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

export default function Counter() {
  return (
    <div>
      {/* counter area start */}
      <div
        className='counter-area bg-relative bg-cover pd-top-110'
        style={{ backgroundImage: 'url("./assets/img/bg/5.png")' }}
      >
        <div className='container pd-bottom-90'>
          <div className='row'>
          <div className="section-title ">
               <h2 className="sectitle-lg text-white text-center">
               Our Data and Analytics Experience
               </h2>
             </div>
            <div
              className='col-lg-6 col-sm-6'
              data-aos='fade-up'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='single-counter-inner'>
                {/*<div className='thumb text-center'>
                  <img src='assets/img/icon/5.svg' alt='img' />
                </div>*/}
                <h2 className='text-white mt-4 mb-2 text-center font-80'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp duration={1} delay={0} start={0} end={5} />
                          +
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white text-center font-30'>Data Consultants</p>
              </div>
            </div>
            <div
              className='col-lg-6 col-sm-6'
              data-aos='fade-up'
              data-aos-delay='150'
              data-aos-duration='1500'
            >
              <div className='single-counter-inner'>
               {/*} <div className='thumb text-center'>
                  <img src='assets/img/icon/6.svg' alt='img' />
                </div>*/}
                <h2 className='text-white mt-4 mb-2 text-center font-80'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter '>
                          <CountUp duration={1} delay={0} start={0} end={100} />
                          's
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white text-center font-30'>Of Successful Data Impementations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter area end */}
    </div>
  )
}
