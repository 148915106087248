import React from "react";
import { SkilledCMSList } from "../../scripts/ServicesLists";
export default function DevSkills() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3 text-center">
                  We Have <span>Skilled CMS Developers</span>
                </h2>
              </div>
              <p className="text-center">
                To improve and elevate your website, application, social media
                network, e-commerce store, or blog, TABS' Custom CMS Solutions
                are success-driven and SEO-friendly. They are made to integrate
                with your current content management system.
              </p>
            </div>
          </div>
          <div className="row">
            {SkilledCMSList.map((data, index) => (
              <div className="col-lg-4 col-md-4" key={index}>
                <div className="dapp-we-offer-service-inner style-3-for-dapp-we-offer text-center">
                  <div className="details">
                    <img className="my-4" src={data.img} alt="img" />
                    <h4 className="mb-3">{data.title}</h4>
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </div>
  );
}
