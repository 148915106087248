import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function CraftingCta() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-90 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="section-title ">
                <h2 className="sectitle-lg">
                  <span>Crafting Excellence</span> in Full Stack Development
                  Services
                </h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <p className="">
                Our skilled and experienced team manages all aspects of full
                stack development to ensure client satisfaction and efficiency.
                We follow best practices and a streamlined workflow to enhance
                development and reduce operating costs.
              </p>
              <h3 className="mt-5">
                <span className="base-span">Have An Idea</span> to Discuss?{" "}
              </h3>
              <Link
                className="btn btn-border-base mt-5"
                data-aos="fade"
                data-aos-delay="300"
                data-aos-duration="1500"
                to="/about"
              >
                Contact Us <FaAngleRight />
              </Link>
            </div>
          </div>
          <div
            className="row justify-content-center hidden-for-mobile"
            style={{ marginTop: "-120px", marginLeft: "60px" }}
          >
            <div className="col-lg-4"></div>
            <div className="col-lg-2 text-center">
              <img
                className="main-img"
                src="assets/img/icon/arrow.png"
                alt="img"
              />
            </div>
            <div className="col-lg-6"></div>
          </div>
          <div className=" bg-gray custom-soft-method-sec ">
            <div className="text-center my-3 p-2">
              <img className="main-img" src="assets/img/icon/consulting.png" alt="img" />
              <h5 className="my-3">Free Consultation</h5>
            </div>
            <div className=" text-center my-3 p-2">
              <img className="main-img" src="assets/img/icon/ideation.png" alt="img" />
              <h5 className="my-3">Ideation</h5>
            </div>
            <div className=" text-center my-3 p-2">
              <img className="main-img" src="assets/img/icon/strategy.png" alt="img" />
              <h5 className="my-3">Strategy</h5>
            </div>
            <div className=" text-center my-3 p-2">
              <img className="main-img" src="assets/img/icon/design.png" alt="img" />
              <h5 className="my-3">Design</h5>
            </div>
            <div className=" text-center my-3 p-2">
              <img className="main-img" src="assets/img/icon/development.png" alt="img" />
              <h5 className="my-3">Development</h5>
            </div>
            <div className=" text-center my-3 p-2">
              <img className="main-img" src="assets/img/icon/testing.png" alt="img" />
              <h5 className="my-3">Testing</h5>
            </div>
            <div className=" text-center my-3 p-2">
              <img className="main-img" src="assets/img/icon/maintenancee.png" alt="img" />
              <h5 className="my-3">Maintenance</h5>
            </div>
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </div>
  );
}
