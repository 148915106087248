import React from "react";
// import Breadcrumb from "../components/Breadcrumb";
// import FooterOne from "../components/FooterOne";
// import NavBar from "../components/NavBar";
import TeamAreaOne from "../../components/TeamAreaOne";
// import WorkProcessOne from "../components/WorkProcessOne";
import NavbarTwo from "../../components/NavbarTwo";
import FooterThree from "../../components/FooterThree";
import AboutBanner from "../AboutUs/components/AboutBanner";
import OurMission from "../AboutUs/components/OurMission";
import AboutUsSec from "../AboutUs/components/AboutUsSec";
import TestimonialOne from '../../components/TestimonialOne'

const About = () => {
  return (
    <div>
      {/* Navigation Bar */}
      <NavbarTwo/>

      {/* Navigation Bar 
      <Breadcrumb title={"About Us"} />
      */}

      <AboutBanner/>

     
      <AboutUsSec/>
  

      {/* About Area Three 
      <AboutAreaThree />*/}

      <OurMission/>

      {/* Team Area One */}
      <TeamAreaOne />

       {/* Testimonial One */}
      <TestimonialOne />

      {/* Footer One */}
      <FooterThree />
    </div>
  );
};

export default About;
