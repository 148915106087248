/*---------------------------------------------------
    ##  TECHNOLOGY STACK LIST START
----------------------------------------------------*/
export const TechnologyStackList = [
  {
    btnid: "nav-frameworks-tab",
    targetid: "#nav-frameworks",
    tappaneid: "nav-frameworks",
    btntitle: "Platforms & Frameworks",
    childdata: [
      {
        id: "platforms1",
        title: "Platforms1",
        logo: "assets/img/about/16.png",
      },
      {
        id: "platforms2",
        title: "Platforms2",
        logo: "assets/img/about/16.png",
      },
      {
        id: "platforms3",
        title: "Platforms3",
        logo: "assets/img/about/16.png",
      },
      {
        id: "platforms4",
        title: "Platforms4",
        logo: "assets/img/about/16.png",
      },
      {
        id: "platforms5",
        title: "Platforms5",
        logo: "assets/img/about/16.png",
      },
    ],
  },
  {
    btnid: "nav-languages-tab",
    targetid: "#nav-languages",
    tappaneid: "nav-languages",
    btntitle: "Programming Languages",
    childdata: [
      {
        id: "programming1",
        title: "Programming",
        logo: "assets/img/about/16.png",
      },
      {
        id: "programming2",
        title: "Programming",
        logo: "assets/img/about/16.png",
      },
      {
        id: "programming3",
        title: "Programming",
        logo: "assets/img/about/16.png",
      },
    ]
  },
  {
    btnid: "nav-database-tab",
    targetid: "#nav-database",
    tappaneid: "nav-database",
    btntitle: "Database & Reporting",
    childdata: [
      {
        id: "database1",
        title: "Database",
        logo: "assets/img/about/16.png",
      },
      {
        id: "database2",
        title: "Databaseeeee",
        logo: "assets/img/about/16.png",
      },
    ]
  },
];

/*---------------------------------------------------
    ##  TECHNOLOGY STACK LIST END
----------------------------------------------------*/

/*---------------------------------------
    ## CMS DEVELOPMENT LIST START
---------------------------------------*/

export const CmsFeaturedList = [
  {
    title: "Education",
    img: "assets/img/icon/education.png"
  },
  {
    title: "Healthcare",
    img: "assets/img/icon/healthcare.png"

  },
  {
    title: "Financial Services",
    img: "assets/img/icon/financialsupport.png"

  },
  {
    title: "Transportation",
    img: "assets/img/icon/transport.png"

  },
  {
    title: "Technology",
    img: "assets/img/icon/technology.png"

  },
  {
    title: "Telecom",
    img: "assets/img/icon/telecommunication.png"

  },
  {
    title: "Energy",
    img: "assets/img/icon/energy.png"

  },
  {
    title: "Real Estate",
    img: "assets/img/icon/real-estate.png"

  },
  {
    title: "Retail",
    img: "assets/img/icon/retail.png"

  },
];

export const SkilledCMSList = [
  {
    title: "CMS Development Services",
    des: "Our experts have extensive experience creating and modifying CMS solutions across various industries. Whether you need a unique CMS platform like WordPress or Joomla, our skilled team can deliver.",
    img: "assets/img/icon/development-service.png",
  },
  {
    title: "CMS Integrations",
    des: "For a more efficient approach to your operations that will increase ROI, we integrate a wide range of necessary plugins, extensions, and tools into your current CMS solutions and business systems.",
    img: "assets/img/icon/data-integration.png",
  },
  {
    title: "CMS Application",
    des: "Our CMS development experts employ the newest technology and market trends to create user-friendly, adaptable, SEO-optimized CMS apps that enhance your digital marketing initiatives and make workflow management affordable.",
    img: "assets/img/icon/application.png",
  },
  {
    title: "CMS Migration",
    des: "Together with rebuilding and updating your website, we'll also carry out a website migration to make it faster to load, avoid using out of date platforms, use HTTPS protocols, and manage more traffic.",
    img: "assets/img/icon/migration.png",
  },
  {
    title: "CMS Plugin Development",
    des: "Whether you want integrated SEO-boosting modules, drag-and-drop buttons, or customizable themes, our CMS professionals can create and deploy a number of powerful plugins that let you add new features and make adjustments whenever you want.",
    img: "assets/img/icon/plugin-development.png",
  },
  {
    title: "CMS Support & Maintenance",
    des: "Our team of committed CMS programmers offers temporary support and maintenance for your custom CMS system, website, or application. They also handle CMS architecture, caching, testing, SEO, and database query optimization.",
    img: "assets/img/icon/support.png",
  },
];

export const WhyChooseCMSList = [
  {
    title: "You'll save time with us",
    des: "Our full-service options, which include project managers, designers, developers, and digital marketers, save you time when coordinating amongst developers, agencies, and independent contractors.",
    img: "assets/img/icon/save-time.png",
  },
  {
    title: "We'll keep your money aside",
    des: "Reduce expenses by utilizing our award-winning agency's one-stop shop for creating a unique content management system.",
    img: "assets/img/icon/save-money.png",
  },
  {
    title: "We're professionals",
    des: "Our award-winning team, which consists of developers, content strategists, and project managers, can provide you with access to industry-specific experts in a range of CMSs.",
    img: "assets/img/icon/professional.png",
  },
];

export const CmsBenefitsListt = [
  {
    title: "Get Instant-Use Plugins",
    des: "It offers a vast selection of plugins and has a sizable community. It makes things simple for different business owners searching for a special solution for their enterprise.",
    img: "assets/img/icon/plugin-development.png",
  },
  {
    title: "Reduced Upkeep Expense",
    des: "Typically, a content management system is used to create and manage websites. This makes it possible for even non-techies to add material to a website and manage it with ease.",
    img: "assets/img/icon/financialsupport.png",
  },
  {
    title: "No Need for Developers",
    des: "The content of a website is its lifeblood. For it to stay current and fresh, changes are required. CMS gives you the ease of using a straightforward interface to create content.",
    img: "assets/img/icon/development-service.png",
  },
  {
    title: "Total Command Over Content",
    des: "Businesses can generate, modify, publish, and archive content with the help of an effective CMS. Subsequently, disseminate said material, data, and information with lightning speed and efficiency.",
    img: "assets/img/icon/content.png",
  },
  {
    title: "Enhance Customer Support",
    des: "From various digital touchpoints, CMS assists in gathering customer data. It also helps to determine how customers are interacting with the brand. It might also offer the company a sense of flexibility.",
    img: "assets/img/icon/support.png",
  },
  {
    title: "Effectively Handle SEO",
    des: "Each page's URL is automatically generated by CMS. SEO is another feature of a quality CMS. In order to support business growth, it ensures that the content will rank well in search engines.",
    img: "assets/img/icon/seo.png",
  },
];

/*---------------------------------------
    ## CMS DEVELOPMENT LIST END
---------------------------------------*/

/*---------------------------------------------------
    ## DATA FORECASTING DEVELOPMENT LIST START
----------------------------------------------------*/

export const DataForecastingService = [
  {
    title: "Time Series Analysis",
    des: "To assist you in foreseeing trends and patterns in your data, we provide thorough time series analysis. You can use this to your advantage when making decisions by looking at your track record.",
    img: "assets/img/icon/time-series-analysis.png",
  },
  {
    title: "Statistical Modeling",
    des: "Our expert team uses a wide range of statistical models, such as ARIMA, exponential smoothing, and regression analysis, to generate accurate predictions for your data.",
    img: "assets/img/icon/statistical-modal.png",
  },
  {
    title: "Machine Learning Algorithms",
    des: "We utilize state-of-the-art machine learning techniques, including gradient boosting, random forests, and neural networks, to construct robust and adaptable prediction models.",
    img: "assets/img/icon/machine-learning-algo.png",
  },
  {
    title: "Data Integration",
    des: "To ensure that data flows freely and that you can make better decisions, we assist you in integrating our forecasting services with your present data architecture.",
    img: "assets/img/icon/data-integration.png",
  },
  {
    title: "Real-time Forecasting",
    des: "Our real-time data forecasting services will keep you abreast of any new information by constantly monitoring the market and revising our predictions accordingly.",
    img: "assets/img/icon/real-time-forecasting.png",
  },
  {
    title: "AI Demand Forecasting",
    des: "We offer demand forecasting services for supply chain and inventory management to optimize stock levels and minimize unnecessary inventory costs.",
    img: "assets/img/icon/ai-demand-forecastng.png",
  },
];

export const DataForecastingStats = [
  {
    title: "Facial Recognition Software",
    des: " As a top AI development company, we utilize our expertise to streamline intricate security challenges. With the implementation of facial recognition software for biometric authentication and access control, your company can significantly enhance its security measures",
    img: "assets/img/icon/fecial-recogonize.png",
  },
  {
    title: "Predictive Analysis",
    des: "If you require assistance in enhancing your business, identifying fraudulent activities, or making accurate forecasts, our dedicated team of AI developers can offer customized predictive analytic solutions tailored to your specific business model. We will assist you in navigating your path to making intelligent decisions with ease.x",
    img: "assets/img/icon/prediction-analysis.png",
  },
  {
    title: "Machine Learning",
    des: "Our team of AI experts utilizes their expertise to create tailored solutions using guided, unsupervised, and reinforced machine learning techniques. These solutions are designed to minimize the need for human intervention in custom artificial intelligence development.",
    img: "assets/img/icon/machine-learning.png",
  },
  {
    title: "NLP",
    des: "Utilize the remarkable capabilities of NLP programming to obtain valuable audience insights. Our TABS company's team has extensive knowledge of NPL algorithms and approaches, allowing businesses to revolutionize user interaction for their specific target audience.",
    img: "assets/img/icon/natural-language-processing.png",
  },
  {
    title: "Computer Vision",
    des: "Our team of experts excels at converting raw photographs and videos into valuable business insights by accurately recognizing and interpreting visual data. Our team leverages computer vision solutions to enhance the accuracy and efficiency of various processes, including biometric authentication, video monitoring, and analytics.",
    img: "assets/img/icon/computer-vision.png",
  },
  {
    title: "Deep Learning",
    des: "As a leading artificial intelligence software development company, we specialize in creating smart solutions that are powered by deep learning algorithms. Utilizing the advanced capabilities of multi-layered neural networks, this leverages enhanced facial recognition, automation, and business intelligence.",
    img: "assets/img/icon/deep-learning.png",
  },
];

/*---------------------------------------------------
    ## DATA FORECASTING DEVELOPMENT LIST END
----------------------------------------------------*/

/*---------------------------------------------------
    ## DATA ANALYTICS  LIST START
----------------------------------------------------*/
export const DataAnalyticsServicesList = [
  {
    title: "Data and Analytics Strategy",
    des: "You can't maximize your company's worth without a data and analytics plan. Together, you and our specialists will devise a solid plan to create an insights-driven organization by bringing your company's objectives, problems, and hopes into harmony.",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Data Architecture and Engineering",
    des: "The bedrock of any insights-driven company's corporate data architecture must be secure, adaptable, and can scale. We provide and manage state-of-the-art architectural solutions after we learn about your company's specific objectives and current IT infrastructure.",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Enterprise Data Governance",
    des: "Being prepared with a solid data governance program is crucial in this digital age. Together, we can build the master data management, data quality, metadata, and governance capabilities that are necessary to enable trustworthy data.",
    img: "assets/img/service-icon/1.png",
  },
  {
    title: "Data Security and Privacy",
    des: "The challenge for organizations is to transform risk into an advantage in this age of rapid technological advancement and widespread digital use. Unlock opportunities and secure your organization with our comprehensive cybersecurity and privacy solutions.",
    img: "assets/img/service-icon/1.png",
  },
  {
    title: "Reporting and Visualization",
    des: "Utilizing a strong data foundation, we leverage analytics and insights to facilitate game-changing business decisions. Our services help you transform data into valuable insights by uncovering patterns and trends, empowering you to make informed decisions.",
    img: "assets/img/service-icon/1.png",
  },
  {
    title: "Advanced Analytics and AI",
    des: "Utilizing advanced analytics and AI can offer even more value by generating predictive insights and uncovering unexpected innovation opportunities. Allow our team to assist you in discovering and backing use cases that drive innovation forward.",
    img: "assets/img/service-icon/1.png",
  },
  
];

export const DataAnalyticsSChooseUsList = [
  {
    title: "Agile & Adaptive",
    des: "Hiring people who can get up to speed in a matter of days rather than months, allows us to quickly expand our capacity or gain access to more knowledge.",
    img: "assets/img/icon/26.svg",
  },
  {
    title: "Rapid Development",
    des: "Rapid prototyping, iterative development, design thinking, and agile DevSecOps can help us facilitate digital innovation.",
    img: "assets/img/icon/26.svg",
  },
  {
    title: "Flexible Engagement",
    des: "Pick the best business model for your company, whether it's T&M, fixed price, or co-development.",
    img: "assets/img/icon/26.svg",
  },
  {
    title: "Dedicated OSDC",
    des: "Over 100 projects have been completed with the help of our offshore, onshore, nearshore, and hybrid OSDC locations around the world.",
    img: "assets/img/icon/26.svg",
  },
];

/*---------------------------------------------------
    ## DATA ANALYTICS SERVICES LIST END
----------------------------------------------------*/

/*---------------------------------------------------
    ## DAPP DEVELOPMENT LIST START
----------------------------------------------------*/
export const DappDevTechStackList = [
  {
    btnid: "nav-dapptechstack-tab",
    targetid: "#nav-dapptechstack",
    tappaneid: "nav-dapptechstack",
    btntitle: "Tech Stack",
    childdata: [
      // {
      //   id: "Hyperledger",
      //   title: "Hyperledger",
      //   logo: "assets/img/about/16.png",
      // },
      // {
      //   id: "Tron",
      //   title: "Tron",
      //   logo: "assets/img/icon/tron.png",
      // },
      // {
      //   id: "Graphene",
      //   title: "Graphene",
      //   logo: "assets/img/icon/graphene.png",
      // },
      // {
      //   id: "Binance",
      //   title: "Binance",
      //   logo: "assets/img/icon/binance.png",
      // },
      {
        id: "Solana",
        title: "Solana",
        logo: "assets/img/icon/solana.png",
      },
      // {
      //   id: "Corda",
      //   title: "Corda",
      //   logo: "assets/img/about/16.png",
      // },
      {
        id: "Ethereum",
        title: "Ethereum",
        logo: "assets/img/icon/ethereum.png",
      },
      // {
      //   id: "Polygon",
      //   title: "Polygon",
      //   logo: "assets/img/icon/polygon.png",
      // },
      // {
      //   id: "Fantom",
      //   title: "Fantom",
      //   logo: "assets/img/icon/fantom.png",
      // },
      // {
      //   id: "Ton",
      //   title: "Ton",
      //   logo: "assets/img/about/16.png",
      // },
      {
        id: "Python",
        title: "Python",
        logo: "assets/img/icon/python.png",
      },
      {
        id: "Solidity",
        title: "Solidity",
        logo: "assets/img/icon/soliditiy.png",
      },
      {
        id: ".NET",
        title: ".NET",
        logo: "assets/img/icon/dotnet.png",
      },
      {
        id: "C++",
        title: "C++",
        logo: "assets/img/icon/cplus.png",
      },
      {
        id: "C#",
        title: "C#",
        logo: "assets/img/icon/chash.png",
      },
      {
        id: "React.js",
        title: "React.js",
        logo: "assets/img/icon/react.png",
      },
      {
        id: "Node.js",
        title: "Node.js",
        logo: "assets/img/icon/nodejs.png",
      },
      {
        id: "Java",
        title: "Java",
        logo: "assets/img/icon/java.png",
      },
      {
        id: "Angular",
        title: "Angular",
        logo: "assets/img/icon/angular.png",
      },
      
    ],
  },
]

export const DappDevServicesList = [
  {
    title: "Rapid Processing",
    des: "The employment of many computers at the same time allows for greater processing speeds as well as optimum utilization of available resources.",
    img: "assets/img/icon/rapid.png",
  },
  {
    title: "Immutable Security",
    des: "Cryptographic protections are pre-installed, and the consensus model safeguards an immutable record that records all modifications.",
    img: "assets/img/icon/secure.png",
  },
  {
    title: "Uptime by Design",
    des: "Hardware problems are eliminated by using several devices, while software glitches are eliminated by using an encrypted, immutable blockchain architecture.",
    img: "assets/img/icon/ddesign.png",
  },
  {
    title: "Trustless and Transparent",
    des: "Because audit trails and blockchain explorers allow users to independently check the dapp's activity, users are not reliant on third parties or each other for confidence.",
    img: "assets/img/icon/transparency.png",
  },
  {
    title: "Audit and Data Trails",
    des: "Know precisely who has accessed which files and when to meet regulatory requirements while also establishing genuine corporate responsibility.",
    img: "assets/img/icon/audit.png",
  },
  {
    title: "Self-managing",
    des: "Decentralized applications (dapps) are less administratively and support-intensive than conventional apps because they are community-managed and run by their code.",
    img: "assets/img/icon/selfmanaging.png",
  },
];

export const DappDevWeOfferList = [
  {
    title: "Custom dapp development",
    des: "If you want a dapp that fits your company's needs precisely, our dapp developers can create one from scratch.",
    img: "assets/img/icon/development.png",
  },
  {
    title: "Dapp consulting",
    des: "To increase the likelihood of success for your dapp, get in-depth assistance from our skilled dapp developers based on thorough research of the market and technology.",
    img: "assets/img/icon/consulting.png",
  },
  {
    title: "Dapp maintenance",
    des: "Support and updates for your decentralized application to keep it running smoothly, adapt to market changes, and accommodate your business's expansion.",
    img: "assets/img/icon/maintenancee.png",
  },
  {
    title: "Dapp testing",
    des: "Before delivering your dapp, our staff thoroughly tests it across all platforms, OSes, and devices to make sure it works.",
    img: "assets/img/icon/testing.png",
  },
  {
    title: "Dapp design",
    des: "Allow our dapp development company to create an interface for your dapp that will captivate users and facilitate easy onboarding for businesses.",
    img: "assets/img/icon/design.png",
  },
  {
    title: "Crypto & fiat integration",
    des: "Take advantage of new sources of income by integrating specialized APIs for automated payments (payables, collections, receivables, etc.) into your existing operations.",
    img: "assets/img/icon/digital-money.png",
  },
  {
    title: "Crypto wallet integration ",
    des: "Create a token wallet for your dapp to store users' digital assets, provide FDIC-compliant asset protection, and enable rapid transactions and money swaps.",
    img: "assets/img/icon/wallet.png",
  },
  {
    title: "Crypto exchange integration",
    des: "Build a DEX where users may connect preferred funding ways, trade numerous tokens across markets, and more for the next-gen trading experience.",
    img: "assets/img/icon/exchange.png",
  },
  {
    title: "KYC integration",
    des: "Enhance your dapp's trustworthiness by incorporating a custom KYC module. This will enable you to verify customer identities and data, as well as prevent fraud.",
    img: "assets/img/icon/execution.png",
  },
];

export const DappDevChooseUsList = [
  {
    title: "Get Familiar with EOSIO",
    des: "If you need assistance creating a decentralized application (DApp), our staff has much experience with the EOSIO platform and can show you how to take advantage of its capabilities.",
  },
  {
    title: "Cloud Infrastructure Expertise",
    des: "As experts in designing and deploying decentralized applications (DApps) on cloud infrastructure, we are able to construct a DApp that is more dependable and scalable.",
  },
  {
    title: "Proficiency with Blockchain Technologies",
    des: "Having deep knowledge of numerous blockchain platforms including Ethereum, BSC, R3 Corda, etc. We counsel you on platform selection and DApp development to meet your company's specific requirements.",
  },
  {
    title: "Mastery of Smart Contract Programming",
    des: "With our developers' extensive knowledge of smart contract programming, you can rest assured that your DApp will be built on solid and secure business logic.",
  },
];

/*---------------------------------------------------
    ## DAPP DEVELOPMENT LIST END
----------------------------------------------------*/

/*---------------------------------------------------
    ## SMAERT CONTRACT DEVELOPMENT LIST Start
----------------------------------------------------*/
export const SmartContractServicesList = [
  {
    title: "SMART CONTRACTS DEVELOPMENT",
    des: "A completely decentralized smart contract can enhance your business logic. Simplify and automate the process of verifying data availability and integrity. As an example, you can use various chains to interact with smart contracts, programs, pallets, and other analogues of chaincode.",
    img: "assets/img/counting-icons/number-1.png",
  },
  {
    title: "SMART CONTRACTS BUSINESS INTEGRATION",
    des: "If you're looking to streamline processes and increase security for your organization, Blaize can help you integrate smart contracts. If you want your transactions and agreements to be as efficient as possible, we can make sure they are automated and self-executing.",
    img: "assets/img/counting-icons/number-2.png",
  },
  {
    title: "TOKEN EMISSION AND DISTRIBUTION",
    des: "It is possible to create bespoke ERC20 utility and governance tokens, as well as smart contracts for basic instruments of work with protocol tokens. These include decentralized autonomous organizations (DAOs), staking, vesting, tokensale, airdrops, and other distribution instruments.",
    img: "assets/img/counting-icons/number-3.png",
  },
  {
    title: "NON-FUNGIBLE TOKENS",
    des: "TABS provides specialized non-fungible token (NFT) development services, employing the ERC721 and ERC1155 standards for a wide range of digital assets. To enable a smooth interface with the most prominent NFT marketplaces and platforms, we expedite the minting procedure.",
    img: "assets/img/counting-icons/number-4.png",
  },
  {
    title: "DEFI, STABLECOINS, DEX AND LIQUID STAKING",
    des: "Easy access to decentralized funding, stablecoins, trading platforms, and staking possibilities is what we do best. With our assistance, you can make the most of these state-of-the-art technologies to improve asset management, boost liquidity, and optimize returns using smart contract-based solutions.",
    img: "assets/img/counting-icons/number-5.png",
  },
  {
    title: "SMART CONTRACT AUDIT",
    des: "For secure and efficient blockchain initiatives, our auditing services are vital. The highest industry standards are followed when we scan your smart contracts for vulnerabilities and fixes. TABS team delivers thorough examinations and practical security and performance recommendations.",
    img: "assets/img/counting-icons/number-6.png",
  },
];

export const SmartContractMethodsList = [
  {
    count: "01",
    title: "Detailing Requirements and Needs",
    des: "To avoid a one-size-fits-all approach, our smart contract development company employs seasoned business analysts to determine the most successful and cost-efficient smart contract solutions.",
    sideimg: "assets/img/counting-icons/arrow-right.png",
  },
  {
    count: "02",
    title: "Developing an Idea and a Comprehensive Plan",
    des: "We make sure our goods stand out in the blockchain world by using industry best practices to build successful strategies for smart contract development.",
    sideimg: "assets/img/counting-icons/arrow-right.png",
  },
  {
    count: "03",
    title: "Smart Contract Development",
    des: "Smart contracts are expertly crafted by TABS engineers with an emphasis on process automation and user data security.",
    sideimg: "assets/img/counting-icons/arrow-right.png",
  },
  {
    count: "04",
    title: "Conclusion and Evaluation",
    des: "To guarantee the utmost security and efficiency, we execute full testing on a testnet and apply comprehensive unit testing coverage. Our security team also does an internal audit to round out the process.",
    sideimg: "assets/img/counting-icons/arrow-right.png",
  },
  {
    count: "05",
    title: "Product Debut and Maintenance",
    des: "To ensure a seamless transition and continuous success, we offer comprehensive smart contract development services, helping clients from the very beginning, when they have an idea, all the way through to after the launch.",
    sideimg: "assets/img/counting-icons/arrow-right.png",
  },
];

export const SmartContractBusinessList = [
  {
    title: "Using the DeFi Tools",
    des: "Through the use of smart contracts, users gain easy access to decentralized financial instruments, which open up a world of secure, direct engagement with a variety of financial services on the blockchain.",
    img: "assets/img/counting-icons/rotated.png",
  },
  {
    title: "Optimization and Streamlining",
    des: "Building smart contracts improves company productivity by automating procedures and making sure jobs are done automatically when criteria are satisfied. Because of how they simplify processes, results are more reliable and devoid of mistakes.",
    img: "assets/img/counting-icons/rotated.png",
  },
  {
    title: "Ensuring Clear and Unchangeable Records",
    des: "Experience the benefits of an immutable ledger that is both transparent and tamper-proof. An auditable record of transactions is created by smart contracts, which promotes accountability and confidence.",
    img: "assets/img/counting-icons/rotated.png",
  },
  {
    title: "Monitoring and Traceability",
    des: "By publicly documenting each and every transaction on the blockchain, smart contracts provide auditability and transparency. All operations are traceable and secure thanks to this transparency, which fosters confidence among participants.",
    img: "assets/img/counting-icons/rotated.png",
  },
  {
    title: "Clear Documentation",
    des: "Smart contracts improve accountability and confidence in corporate operations by preserving transactions on the blockchain, which makes data available and impossible to tamper with. This leads to transparent record-keeping.",
    img: "assets/img/counting-icons/rotated.png",
  },
  {
    title: "Speed on a Global Scale",
    des: "Your company's operations can be accessed from anywhere in the world with smart contracts powered by blockchain technology. Make safe, international purchases without ever having to think about where you are.",
    img: "assets/img/counting-icons/rotated.png",
  },
];

/*---------------------------------------------------
    ## SMAERT CONTRACT DEVELOPMENT LIST END
----------------------------------------------------*/

/*---------------------------------------------------
    ## CRYPTO DEVELOPMENT LIST START
----------------------------------------------------*/

export const CryptoDevBenefitsList = [
  {
    title: "Enhanced Convenience",
    des: "Our clients can easily implement sophisticated trading strategies with the help of our cutting-edge algorithmic automated trading bots.",
  },
  {
    title: "Less Sway on Emotions",
    des: "Without human intervention or bias, the AI bot trading platform continues to trade in accordance with a predefined plan.",
  },
  {
    title: "Ongoing Function",
    des: "Traders can avoid missing out on profitable trading opportunities by utilizing AI trading bots. These computers work nonstop, eliminating human error.",
  },
  {
    title: "Executing Strategies Consistently",
    des: "Automated trade execution according to pre-defined parameters is made possible by the AI bot trading platform's specific algorithmic protocols.",
  },
  {
    title: "Faster and More Efficient",
    des: "With lightning speed, our crypto auto trading bot scans the market, finds signals, and makes trades, seizing even the tiniest profitable possibilities.",
  },
  {
    title: "Taking on Multiple Roles at Once",
    des: "Bots powered by artificial intelligence can trade on several exchanges and evaluate market data for multiple cryptocurrencies all at once, unlike humans.",
  },
];

export const CryptoDevFeaturesList = [
  {
    title: "24/7 Automated Trade",
    img: "assets/img/icon/247service.png",
  },
  {
    title: "Live Market Data Collection",
    img: "assets/img/icon/data-collection.png",
  },
  {
    title: "Indicators Computation",
    img: "assets/img/icon/indicator.png",
  },
  {
    title: "Easy Execution Of Orders",
    img: "assets/img/icon/execution.png",
  },
  {
    title: "Simulates Order Completion Process Accurately",
    img: "assets/img/icon/completion.png",
  },
  {
    title: "Exact Forecast Of Profit And Risk Metrics",
    img: "assets/img/icon/profit-risk-metrics.png",
  },
  {
    title: "Graphically Result Representation On Web",
    img: "assets/img/icon/graphically-result.png",
  },
  {
    title: "Management And Import Of Historical Market Data",
    img: "assets/img/icon/market-data.png",
  },
];

export const CryptoDevHelpList = [
  {
    title: "Automated Investing Options",
    img: "assets/img/icon/save-money.png",
  },
  {
    title: "Faster Speeds And More Efficiency",
    img: "assets/img/icon/speed.png",
  },
  {
    title: "More Time On The Market",
    img: "assets/img/icon/market-data.png",
  },
];

/*---------------------------------------------------
    ##  CRYPTO DEVELOPMENT LIST END
----------------------------------------------------*/

/*---------------------------------------------------
    ##  GENERATIVE AI DEVELOPMENT LIST START
----------------------------------------------------*/
export const GenerativeAiWhyChooseList = [
  {
    title: "POC's in Weeks",
    des: "Our lightning-fast Proof of Concept (POC) deployments will hasten your journey to transformation. Convert use cases into potential outcomes in a matter of weeks.",
    img: "assets/img/icon/26.svg",
  },
  {
    title: "Right Expertise",
    des: "Our seasoned AI experts can help your company innovate by addressing the specific challenges faced by companies in a wide range of industries.",
    img: "assets/img/icon/26.svg",
  },
  {
    title: "Custom and Tailored Solutions",
    des: "By adapting our generative AI services to meet the specific needs of your company, we guarantee that they will have the greatest possible impact.",
    img: "assets/img/icon/26.svg",
  },
  {
    title: "Strong Technology Partnerships",
    des: "We offer unmatched technological knowledge through our ecosystem of technology partners in areas such as cloud computing, data & AI, automation, and more.",
    img: "assets/img/icon/26.svg",
  },
  {
    title: "Data Security",
    des: "In order to guarantee the safety of the sensitive information belonging to your company, we place a high priority on data security and compliance.",
    img: "assets/img/icon/26.svg",
  },
  {
    title: "ROI-Driven",
    des: "You will be able to accomplish your business objectives with the assistance of our services, which are designed to provide a measurable return on investment.",
    img: "assets/img/icon/26.svg",
  },
];

/*---------------------------------------------------
    ##  GENERATIVE AI DEVELOPMENT LIST END
----------------------------------------------------*/

/*---------------------------------------------------
    ##  HYPER LEGER DEVELOPMENT LIST START
----------------------------------------------------*/
export const HyperLegerServicesList = [
  {
    title: "Chaincode Development",
    des: "Chaincodes are the term used for smart contracts on the Hyperledger blockchain platform. The chaincodes facilitate the development of business contracts, decentralized applications, and asset definitions. We have the ability to develop chaincodes on Hyperledger Fabric.",
  },
  {
    title: "Architecture & Design",
    des: "With our expertise in project management, we carefully evaluate your needs and develop tailored applications that deliver optimal results for your business. We develop a comprehensive technical design that encompasses database design, system blueprint, user stories, and more.",
  },
  {
    title: "Hyperledger Consulting",
    des: "We conduct an analysis of the needs that your company has and investigate the possible Hyperledger solutions that may be suitable for meeting those criteria. The identification of the most suitable solution for your company and the advantages it offers is one of the goals of our staff consultants.",
  },
  {
    title: "Daapp Development",
    des: "Excellent decentralized applications (DApps) built with the Hyperledger frameworks by our team of expert Blockchain professionals for a wide range of industries. Several iterations of the app are made available prior to release using an agile development technique",
  },
  {
    title: "Upgrades & Migration",
    des: "Upgrading or transferring your current Blockchain infrastructure to the Hyperledger architecture is something we can assist you with. We also guarantee that there will be no downtime while we work on the move to keep company procedures running smoothly.",
  },
  {
    title: "Support",
    des: "We also offer assistance with various services such as defining technical components, providing database design, and developing model definitions. This involves creating a comprehensive business network definition that includes rules, functions, and logic.",
  },
];

export const HyperLegerChooseUsList = [
  {
    title: "Permissioned membership",
    des: "Every participant on Hyperledger is assigned identifiable IDs. We possess expertise in utilizing Hyperledger for blockchain applications that require adherence to data protection regulations, and we can investigate its implementation as a 'permissioned' network.",
  },
  {
    title: "Efficiency, scalability, and confidence",
    des: "Our developers are skilled in breaking down transaction processing into three stages using the modular architecture of Hyperledger Fabric: distributed logic processing and agreement (referred to as 'chain code'), transaction ordering, and transaction validation and commitment.",
  },
  {
    title: "Data that must be known",
    des: "Due to restrictions controlling the secrecy of personal data, protection laws, and competition, businesses are required to keep certain data pieces private. Hyperledger data partitioning is something we can help with.",
  },
  {
    title: "Programs that prioritize performance",
    des: "Thanks to the Hyperledger blockchain framework, we can rest assured that the Byzantine Fault Tolerance problem is no longer a concern. We are able to develop highly efficient applications that are suitable for enterprise-level use cases.",
  },
];

export const HyperLegerTechStackList = [
  {
    btnid: "nav-techstack-tab",
    targetid: "#nav-techstack",
    tappaneid: "nav-techstack",
    btntitle: "Tech Stack",
    childdata: [
      {
        id: "APTOS",
        title: "APTOS",
        logo: "assets/img/about/16.png",
      },
      {
        id: "SUI",
        title: "SUI",
        logo: "assets/img/about/16.png",
      },
      {
        id: "BINANCE",
        title: "BINANCE SMART CHAIN",
        logo: "assets/img/about/16.png",
      },
      {
        id: "POLYGON",
        title: "POLYGON",
        logo: "assets/img/about/16.png",
      },
      {
        id: "AVALANCHE",
        title: "AVALANCHE",
        logo: "assets/img/about/16.png",
      },
      {
        id: "ARBITRUM",
        title: "ARBITRUM",
        logo: "assets/img/about/16.png",
      },
      {
        id: "CARDANO",
        title: "CARDANO",
        logo: "assets/img/about/16.png",
      },
      {
        id: "R3",
        title: "R3 CORDA",
        logo: "assets/img/about/16.png",
      },
      {
        id: "HEDERA",
        title: "HEDERA HASHGRAPH",
        logo: "assets/img/about/16.png",
      },
    ],
  },
]

/*---------------------------------------------------
    ##  HYPER LEGER DEVELOPMENT LIST END
----------------------------------------------------*/

/*---------------------------------------------------
    ##  Custom Software DEVELOPMENT LIST Start
----------------------------------------------------*/
export const CustomDevTechnologyStackList = [
  {
    btnid: "nav-frameworks-tab",
    targetid: "#nav-frameworks",
    tappaneid: "nav-frameworks",
    btntitle: "Platforms & Frameworks",
    childdata: [
      {
        id: "Aws",
        title: "Aws",
        logo: "assets/img/icon/aws1.png",
      },
      {
        id: "SharePoint",
        title: "SharePoint",
        logo: "assets/img/icon/sharepoint.png",
      },
      {
        id: "Kotlin",
        title: "Kotlin",
        logo: "assets/img/icon/kotlin.png",
      },
      {
        id: "Azure",
        title: "Azure",
        logo: "assets/img/icon/azure.png",
      },
      {
        id: "Android Studio",
        title: "Android Studio",
        logo: "assets/img/icon/androidstudio.png",
      },
      {
        id: "Google Cloud",
        title: "Google Cloud",
        logo: "assets/img/icon/googlecloud.png",
      },
      {
        id: "Microsofy.NET",
        title: "Microsofy.NET",
        logo: "assets/img/icon/dotnet.png",
      },
      {
        id: "Salesforce",
        title: "Salesforce",
        logo: "assets/img/icon/salesforce.png",
      },
      {
        id: "Angular",
        title: "Angular",
        logo: "assets/img/icon/angular.png",
      },
      {
        id: "Adobe Experience Manager",
        title: "Adobe Experience Manager",
        logo: "assets/img/icon/adobe.png",
      },
      {
        id: "React",
        title: "React",
        logo: "assets/img/icon/react.png",
      },
      {
        id: "Rails",
        title: "Rails",
        logo: "assets/img/icon/rails.png",
      },
      {
        id: "Microsoft Dynamics",
        title: "Microsoft Dynamics",
        logo: "assets/img/icon/dynamic.png",
      },
    ],
  },
  {
    btnid: "nav-languages-tab",
    targetid: "#nav-languages",
    tappaneid: "nav-languages",
    btntitle: "Programming Languages",
    childdata: [
      {
        id: "Node JS",
        title: "Node JS",
        logo: "assets/img/icon/nodejs.png",
      },
      // {
      //   id: "JD",
      //   title: "JD",
      //   logo: "assets/img/about/16.png",
      // },
      {
        id: "Python",
        title: "Python",
        logo: "assets/img/icon/python.png",
      },
      {
        id: "golang",
        title: "golang",
        logo: "assets/img/icon/go.png",
      },
      {
        id: "Swift",
        title: "Swift",
        logo: "assets/img/icon/swift.png",
      },
      {
        id: ".NET",
        title: ".NET",
        logo: "assets/img/icon/dotnet.png",
      },
      {
        id: "C#",
        title: "C#",
        logo: "assets/img/icon/chash.png",
      },
      {
        id: "Java",
        title: "Java",
        logo: "assets/img/icon/java.png",
      },
      {
        id: "[OBJ-C]",
        title: "[OBJ-C]",
        logo: "assets/img/icon/c.png",
      },
      {
        id: "Php",
        title: "Php",
        logo: "assets/img/icon/php.png",
      },
      {
        id: "React Native",
        title: "React Native",
        logo: "assets/img/icon/reactnative.png",
      },
      {
        id: "R",
        title: "R",
        logo: "assets/img/icon/r.png",
      },
    ]
  },
  {
    btnid: "nav-database-tab",
    targetid: "#nav-database",
    tappaneid: "nav-database",
    btntitle: "Database & Reporting",
    childdata: [
      {
        id: "MongoDB",
        title: "MongoDB",
        logo: "assets/img/icon/mongodb.png",
      },
      // {
      //   id: "CASSANDRA",
      //   title: "CASSANDRA",
      //   logo: "assets/img/about/16.png",
      // },
      {
        id: "PostgreSQL",
        title: "PostgreSQL",
        logo: "assets/img/icon/postgresql.png",
      },
      {
        id: "Hadoop",
        title: "Hadoop",
        logo: "assets/img/icon/hadoop.png",
      },
      // {
      //   id: "COGNOS",
      //   title: "COGNOS",
      //   logo: "assets/img/about/16.png",
      // },
      {
        id: "MySQL",
        title: "MySQL",
        logo: "assets/img/icon/mysql.png",
      },
      // {
      //   id: "Pentaho",
      //   title: "Pentaho",
      //   logo: "assets/img/about/16.png",
      // },
      {
        id: "ORACLE",
        title: "ORACLE",
        logo: "assets/img/icon/oracle.png",
      },
      {
        id: "AWS",
        title: "AWS",
        logo: "assets/img/icon/aws1.png",
      },
      {
        id: "Jasper",
        title: "Jasper",
        logo: "assets/img/icon/jasper.png",
      },
    ]
  },
];
export const CustomSoftServiceList = [
  {
    title: "Launching a New App",
    des: "When introducing a novel concept, like your own application, our software development services may add a personal touch to your idea and guarantee a noteworthy launch.",
  },
  {
    title: "Modernizing a Pre-existing App",
    des: "Add new and improved features and functionalities to your existing application. App revitalization through modernization and greater user engagement is what our custom software development services deliver.",
  },
  {
    title: "Cross-Platform Development Adoption",
    des: "Consider working with us on cross-platform development if you're prepared to provide your program to a wider audience. Our assistance enables you to increase your app's reach.",
  },
];

export const CustomSoftServicesList = [
  {
    headingid: "headingOne",
    collapseid: "collapseOne",
    collapseidcall: "#collapseOne",
    heading: "Arrangement and Conditions",
    childdata: [
      {
        title: "Setting Clear Goals",
        des: "To guarantee clarity and concentration, begin by establishing the project's precise needs and scope.",
      },
      {
        title: "Collaborative Input",
        des: "Include important stakeholders in the requirements collecting process to collect a range of needs and viewpoints.",
      },
      {
        title: "Methodology Selection",
        des: "Based on the objectives and nature of the project, select either the waterfall or agile development approaches.",
      },
    ],
  },
  {
    headingid: "headingTwo",
    collapseid: "collapseTwo",
    collapseidcall: "#collapseTwo",
    heading: "Creating and Designing",
    childdata: [
      {
        title: "User-Friendly Architecture",
        des: "Make it a priority to design with user needs and accessibility guidelines in mind.",
      },
      {
        title: "Adaptable Design",
        des: "Choose a modular architecture or micro services to create an application that is easy to expand and change.",
      },
      {
        title: "Prioritizing Quality",
        des: "To guarantee high-quality results, adopt Test-Driven Development (TDD) and continuous integration.",
      },
      {
        title: "Standardized Coding",
        des: "In order to keep consistency and readability, adhere to recognized code standards, such as PEP8 for Python.",
      },
    ],
  },
  {
    headingid: "headingThree",
    collapseid: "collapseThree",
    collapseidcall: "#collapseThree",
    heading: "Coordinating and Communicating Projects",
    childdata: [
      {
        title: "Future-Proofing",
        des: "Make plans for regular upgrades and maintenance using DevOps techniques.",
      },
      {
        title: "Efficient Communication",
        des: "To coordinate teamwork, use platforms for clear and efficient communication like Slack and JIRA.",
      },
      {
        title: "Version Control",
        des: "To monitor and handle code changes, use version control systems like SVN or Git.",
      },
      {
        title: "Detailed Documentation",
        des: "For comprehensive code and process documentation, use technologies such as Confluence.",
      },
      {
        title: "Agile Management",
        des: "To improve agile project management, put Scrum or Kanban frameworks into practice.",
      },
      {
        title: "Budgeting for Flexibility",
        des: "Set up money for unforeseen debt or technological difficulties.",
      },
      {
        title: "Adaptive Project Strategy",
        des: "Stay adaptable and open to changing tactics as the project progresses.",
      },
    ],
  },

  {
    headingid: "headingFour",
    collapseid: "collapseFour",
    collapseidcall: "#collapseFour",
    heading: "Testing, Deployment, and Improvement",
    childdata: [
      {
        title: "User-Centric Iteration",
        des: "Constantly incorporate user feedback for iterative development and improvement.",
      },
      {
        title: "Empowering Users",
        des: "Give users thorough instructions and documentation so they can get the most out of the program.",
      },
      {
        title: "Performance Monitoring",
        des: "Make use of analytics and monitoring solutions for continuous insights and performance assessment.",
      },
      {
        title: "echnological Advancement",
        des: "Investigate and include cutting-edge technology like blockchain and artificial intelligence when appropriate.",
      },
      {
        title: "Reflective Improvement",
        des: "Review the project once it has been completed to find areas that could use better.",
      },
    ],
  },
];

export const CustomSoftDevServicesList = [
  {
    title: "Custom Software Development",
    des: "By developing solutions in line with their business goals, custom software development gives firms a competitive edge, improves productivity, and streamlines operations. Moreover, custom software solutions are flexible and can be modified to meet changing requirements.",
  },
  {
    title: "Enterprise Software Development",
    des: "Large firms benefit greatly from enterprise software development's ability to streamline operations, increase output, and promote innovation. These solutions guarantee the seamless integration of all new and current technologies and their adaptability to changing business environments.",
  },
  {
    title: "Software Product Development",
    des: "Software Product Development Services help firms create market-ready software solutions. Whether they are startups looking to disrupt industries or established businesses looking to diversify their product portfolios, custom product development is critical for bringing ideas to fruition.",
  },
  {
    title: "Software Integration Services",
    des: "By streamlining procedures, software solutions integration enables disparate systems to operate in harmony, exchanging data and fostering a cohesive and coordinated process flow.",
  },
  {
    title: "API Development",
    des: "Apis are essential for communicating with cloud-based backend applications and enabling remote access to products and devices. Interfaces between software programs are mostly supported by APIs.",
  },
  {
    title: "SaaS Development",
    des: "Software as a Service (SaaS) is a popular cloud-based model that lets users' access applications online without installation or local storage. It simplifies deployment, updates, and maintenance.",
  },
];

export const CustomSoftFeatureList = [
  {
    title: "Logistics",
    img: "assets/img/icon/Logistics.png",
  },
  {
    title: "Retail & ecommerce",
    img: "assets/img/icon/retail.png",
  },
  {
    title: "On Demand",
    img: "assets/img/icon/application.png",
  },
];

/*---------------------------------------------------
    ##  Custom Software DEVELOPMENT LIST END
----------------------------------------------------*/

/*---------------------------------------------------
    ##  Full Stack DEVELOPMENT LIST START
----------------------------------------------------*/
export const FullStackChooseUsList = [
  {
    title: "Client satisfaction on every project",
    des: "As a well-known full stack development business, we have integrated with the newest tools and technologies to build over 100 full stack web apps. Our full stack developers make care to complete projects in accordance with client specifications.",
  },
  {
    title: "Tailored Solutions for Your Business Needs",
    des: "Being a well-known fullstack development company, we are aware that your organization needs a customized solution to achieve your goals. We provide affordable custom corporate full stack solutions to give you an edge over rivals.",
  },
  {
    title: "Safe and Risk-Free Engagement Choices",
    des: "Try us if you have any questions regarding specific information! When hiring dedicated software developers, consider TABS' risk-free engagement option first. We're sure you'll stick with us with our extensive MVCs, and we'll use our knowledge to assist you achieve business advantages.",
  },
  {
    title: "Inexpensive Mobile and Web Services",
    des: "Our full stack web and mobile app development experience and expertise have been carefully chosen, enabling us to provide cost-effective solutions that perfectly align with your needs and make your vision a reality. Let us help you achieve your goals.",
  },
  {
    title: "Absolute Quality Assurance Standard",
    des: "At TABS, we continue to have a strong commitment to providing full stack apps with the best possible capabilities and quickly resolve any problems through iterative development until the applications fulfill the objectives. Get in touch with us to guarantee the best possible quality assurance.",
  },
  {
    title: "24/7 Upkeep and Technical Assistance",
    des: "When you need efficient technical help, you can reach out to our software development teams, who are on call around-the-clock. In order for us to remain competitive, you can be confident that we will respond quickly and provide a solution.",
  },
];

export const FullStackSolutionsList = [
  {
    title: "Front End Development",
    des: "Our full-stack development team specializes in creating and selecting engaging front ends for applications. The group is proficient in all of the most contemporary front-end development languages, including AngularJS, Ext.JS, Mocha, React, Vue.JS, and others.",
  },
  {
    title: "Backend Development",
    des: "Because our product designers are always working with cutting-edge technologies like.NET, Java, Node.js, PHP, Python, and more that give state-of-the-art results while saving you development time and money, working with our back-end developers guarantees you will obtain the most attractive outputs.",
  },
  {
    title: "Database Design",
    des: "Our in-house database designers and managers are proficient in the most recent versions of IBM Db2, MongoDB, MySQL, NoSQL, PostgreSQL, and other databases. Utilize cutting-edge technologies to create reliable databases that manage, retrieve, and store data for optimal application performance.",
  },
  {
    title: "API Integration",
    des: "Payment networks, social media, geo-services, email marketing, business tools, and other services are just a few of the services that our development teams integrate. in place of integrating with non-customized third-party APIs.",
  },
  {
    title: "Mobile App Development",
    des: "Our Full Stack development team makes intuitive, fast mobile applications. Utilizing a cutting-edge technology stack, we create apps that maximize performance on both iOS and Android platforms, creating a distinctive user experience and increasing engagement.",
  },
  {
    title: "Full Stack Migration and Porting",
    des: "With ease, our full stack engineers at TABS can migrate or port online and mobile applications to the full stack technologies of your choice. We guarantee no data loss, maximum performance, little interruption, and little downtime when we migrate or port your apps.",
  },
  {
    title: "Maintenance and Support",
    des: "To keep your system safe and operating at peak efficiency, TABS offers a wide range of maintenance and support services. To maintain the smooth running of your company, we provide bug fixes, performance optimization, security upgrades, and more around-the-clock.",
  },
  {
    title: "Full Stack Consultancy Services",
    des: "Are you having trouble coming up with a complete stack solution for your business needs? You can start your full stack journey with confidence thanks to TABS's knowledgeable advisors, who offer advice based on the most recent market trends and data-supported insights.",
  },
];

/*---------------------------------------------------
    ##  Full Stack DEVELOPMENT LIST END
----------------------------------------------------*/

export const CmsFeaturedListt = [];
