import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
export default function OurServices() {
  return (
    <div>
      {/* =============== About Area Three End ===============*/}
      <div className="about-area  pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner mb-4 mb-lg-0">
                <img
                  className="main-img"
                  src="assets/img/images/cms-developer-1.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h2 className="sectitle-lg">
                  <span>Hire</span> CMS developers
                </h2>
                <p className="content mb-4">
                  Strong, safe, and scalable enterprise-grade web applications
                  are created by our goal-oriented CMS developers. Acquire it
                  with clean code and user-centric designs.
                </p>
                <div className="row">
                  <div className="col-md-12">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li className="mb-4">
                        <FaCheckCircle /> Create dynamic, data-driven apps by
                        combining several technologies.
                      </li>
                      <li className="mb-4">
                        <FaCheckCircle /> Quick project completion with a wide
                        range of expansion opportunities.
                      </li>
                      <li className="mb-4">
                        <FaCheckCircle /> Well-versed in front-end, back-end and
                        DevOps.
                      </li>
                      <li className="">
                        <FaCheckCircle /> Competence with updates and
                        maintenance.
                      </li>
                    </ul>
                  </div>
                </div>
                <Link className="btn btn-border-base" to="/about">
                  Hire CMS Masters
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Three End ===============*/}
    </div>
  );
}
