import React from "react";
import { DappDevServicesList } from "../../scripts/ServicesLists";

export default function OurServices() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3 text-center">
                  <span>Digital Application Development</span>
                  <br /> for Your Business
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {DappDevServicesList.map((data, index) => (
              <div className="col-lg-4 col-md-4 my-4" key={index}>
                <div className="dapp-dev-service-inner style-3-for-dapp-dev">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4>{data.title}</h4>
                    <div className="thumb">
                      <img src={data.img} alt="img" />
                    </div>
                  </div>
                  <div className="details">
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
