import React from "react";
// import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
// import ModalVideo from "react-modal-video";

export default function HomeBanner() {
  return (
    <div>
      {/* ================== BannerTwo Start ==================*/}
      <div
        className='banner-area bg-relative banner-area-2 bg-cover'
        style={{ backgroundImage: 'url("./assets/img/banner/0.png")' }}
      >
        <div className='container '>
          <div className='row'>
            <div className='col-lg-6 align-self-center'>
              <div className='banner-inner pe-xl-5'>
                <h6
                  className='subtitle '
                  data-aos='fade-right'
                  data-aos-delay='100'
                  data-aos-duration='1500'
                >
                  Designing for the future
                </h6>
                <h2
                  className='title '
                  data-aos='fade-right'
                  data-aos-delay='200'
                  data-aos-duration='1500'
                >
                  {" "}
                  Innovate Your <span>Future with TABS</span>
                  
                </h2>
                <Link
                  className='btn btn-border-base '
                  data-aos='fade-right'
                  data-aos-delay='300'
                  data-aos-duration='1500'
                  to='/about'
                >
                  Discover More 
                </Link>
                <p
                  className='content pe-xl-5 py-3 '
                  data-aos='fade-right'
                  data-aos-delay='250'
                  data-aos-duration='1500'
                >
                Unlock the potential of the latest technologies with our expert team. Experience excellence, innovation, and ensure results tailored to your needs.
                </p>  
              </div>
            </div>
            <div
              className='col-lg-6 col-md-9 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='banner-thumb-2 mt-4 mt-lg-0'>
              
                <div className='main-img-wrap'>
                 {/*} <img
                    className='banner-animate-img banner-animate-img-1 left_image_bounce'
                    src='assets/img/banner-2/4.png'
                    alt='img'
  />*/}
                 
                 
                  <img
                    className='main-img'
                    src='assets/img/common/bannertwoimg.png'
                    alt='img'
                  />
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>

      {/* ================== BannerTwo End ==================*/}
    </div>  
  )
}
