import React from "react";
import { GenerativeAiWhyChooseList } from "../../scripts/ServicesLists";

export default function WhyChooseUs() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative bg-gray pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="mb-5">
              <div className="section-title ">
                <h2 className="sectitle-lg">
                  <span>Why</span> Choose Us
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {GenerativeAiWhyChooseList.map((data, index) => (
              <div className="col-lg-4 col-md-6 mb-5" key={index}>
                <div className="media-for-choose-us-generative mb-4">
                  <div className="media-left me-3">
                    <img src={data.img} alt="img" />
                  </div>
                  <div className="media-body-for-choose-us-generative">
                    <h4>{data.title}</h4>
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
