import React from "react";
import NavbarTwo from "../../components/NavbarTwo";
import SubServiceBanner from "../../common_components/SubServiceBanner";
import CtaSec from "../../common_components/CtaSec";
import WeWork from "../../common_components/WeWork";
import ContactSec from "../../common_components/ContactSec";
import FooterThree from "../../components/FooterThree";
import OurServices from "./OurServices";
import GoLang from "./GoLang";
import HireNowSec from "./HireNowSec";
import WhyChosseUs from "./WhyChosseUs";
// import TechnologyStack from "../../common_components/TechnologyStack";
// import { HyperLegerTechStackList } from "../../scripts/ServicesLists";

export default function HyperLeger() {
  return (
    <div>
      <NavbarTwo />
      <SubServiceBanner
        subheading="Service"
        title="Hyperledger Development"
        blueText="Services"
        paragraph="Get your company up and running with our enterprise-level distributed Hyperledger development services and a Hyperledger blockchain architecture that supports smart contracts."
      />
      <OurServices />
     {/*} <TechnologyStack
        listname={HyperLegerTechStackList}
        bluetext="Expertise"
        title="in Frameworks"
      />*/}
      <GoLang />
      <HireNowSec />
      <WhyChosseUs />
      <WeWork />
      <CtaSec />
      <ContactSec />
      <FooterThree />
    </div>
  );
}
