import React from "react";
import { DappDevWeOfferList } from "../../scripts/ServicesLists";

export default function WeOffer() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90 bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3 text-center">
                  What We <span>Offer</span>
                </h2>
                <p className="text-center">
                  Offering everything from full-stack development to consulting
                  and support, the TABS development business covers all bases
                  when it comes to decentralized application (dapp) development.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {DappDevWeOfferList.map((data, index) => (
              <div className="col-lg-4 col-md-4" key={index}>
                <div className="dapp-we-offer-service-inner style-3-for-dapp-we-offer text-center">
                  <div className="details">
                    <img
                      className="my-4"
                      src={data.img}
                      alt="img"
                    />
                    <h4 className="mb-3">{data.title}</h4>
                    <p>
                      {data.des}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
