import React from "react";
import { SmartContractMethodsList } from "../../scripts/ServicesLists";
export default function OurMethod() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative  pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="mb-5">
              <div className="section-title ">
                <h2 className="sectitle-lg">
                  Our Method of <span>Collaboration</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {SmartContractMethodsList.map((data, index) => (
              <div className="col-lg-12 col-md-6 mb-5" key={index}>
                <div className="media-for-choose-us">
                  <div className="media-left me-3">
                    <img className="mx-3" src={data.sideimg} alt="img" />
                  </div>
                  <div className="media-body-for-choose-us">
                    <h1 className="text-base">{data.count}</h1>
                    <h4>{data.title}</h4>
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
