import React from "react";

export default function DevelopFor() {
  return (
    <div>
      {/* career area start */}
      <div className="career-section pd-top-120 pd-bottom-90 bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-title">
                {/*<h6 className='sub-title'>ABOUT US</h6>*/}
                <h2 className="sectitle-lg">
                  We Design and Develop <span> Apps For</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-5">
              <p className="mb-5">
                Companies of all sizes, from startups to multinational
                conglomerates, can take use of TAB Solution's web application
                development services in numerous ways. The software that
                organizations receive is one-of-a-kind and designed to meet
                their unique specifications and demands.
              </p>
            </div>
          </div>
          <div className="web-dev-wrap-area">
            <nav>
              <div
                className="nav nav-tabs web-dev-nav-tab"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active my-3"
                  id="nav-Large-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-Large"
                  type="button"
                  role="tab"
                  aria-controls="nav-Large"
                  aria-selected="true"
                >
                  Large businesses
                </button>
                <button
                  className="nav-link my-3"
                  id="nav-Small-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-Small"
                  type="button"
                  role="tab"
                  aria-controls="nav-Small"
                  aria-selected="false"
                >
                  Small & medium businesses
                </button>
                <button
                  className="nav-link my-3"
                  id="nav-Startups-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-Startups"
                  type="button"
                  role="tab"
                  aria-controls="nav-Startups"
                  aria-selected="false"
                >
                  Startups
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane show active"
                id="nav-Large"
                role="tabpanel"
                aria-labelledby="nav-Large-tab"
              >
                <div className="web-dev-wrap">
                  <div className="row">
                    <div className="col-lg-12">
                      <h3 className=" my-3">
                        <span className="base-span">Large</span> Businesses
                      </h3>

                      <p className="mb-3">
                        To assist large organizations in resolving urgent
                        problems related to ineffective time management, finance
                        and business resource
                      </p>
                      {/*}  <div className='row'>
                     <div className='col-md-6'>
                       <ul className='single-list-inner style-check style-heading style-check mb-3'>
                         <li>
                           <FaCheckCircle /> Mistakes To Avoid to the
                         </li>
                         <li>
                           <FaCheckCircle /> Your Startup industry stan
                         </li>
                       </ul>
                     </div>
                     <div className='col-md-6'>
                       <ul className='single-list-inner style-check style-heading style-check mb-3'>
                         <li>
                           <FaCheckCircle /> Mistakes To Avoid to the
                         </li>
                         <li>
                           <FaCheckCircle /> Your Startup industry stan
                         </li>
                       </ul>
                     </div>
                   </div>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane"
                id="nav-Small"
                role="tabpanel"
                aria-labelledby="nav-Small-tab"
              >
                <div className="web-dev-wrap">
                  <div className="row">
                    <div className="col-lg-12">
                      <h3 className="my-3">
                        <span className="base-span">Small And Medium</span>{" "}
                        Businesses
                      </h3>
                      <p className="mb-3">
                        Small businesses can save time and money by using TABS's
                        specialized software solutions to automate
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane"
                id="nav-Startups"
                role="tabpanel"
                aria-labelledby="nav-Startups-tab"
              >
                <div className="web-dev-wrap">
                  <div className="row">
                    <div className="col-lg-12">
                      <h3 className="my-3">
                        <span className="base-span">Startups</span>
                      </h3>
                      <p className="mb-3">
                        By providing entrepreneurs with bespoke web development
                        services, we enable them to save money on web
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* career area start */}
    </div>
  );
}
