import React from "react";
import { CmsBenefitsListt } from "../../scripts/ServicesLists";

export default function Benefits() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 col-md-12">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3 text-center">
                  <span>Benefits of CMS</span> for Your Business
                </h2>
              </div>
              <p className="text-center mb-5">
                Boost productivity with an easy-to-use content management
                system. You can now manage content much more effectively without
                knowing any code. Reduce extra work and expedite the process:
                The sophisticated features enable prompt content management.
              </p>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="row my-3">
            {CmsBenefitsListt.map((data, index) => (
              <div className="col-lg-4 col-md-6 mb-3" key={index}>
                <div className="crypto-feature-service-inner style-3-for-crypto-feature px-4 py-4">
                  <div className="thumb">
                    <img src={data.img} alt="img" />
                  </div>
                  <div className="details">
                    <h5>{data.title}</h5>
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
