import React from "react";
import { CustomSoftDevServicesList } from "../../scripts/ServicesLists";

export default function DevServices() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3">
                  Entire <span>Portfolio of Personalized</span> Software
                  Development Services
                </h2>
                <p className="">
                  The core of our custom software development process is a
                  hyper-focused methodology that places an emphasis on creating
                  extremely effective solutions and customizing user
                  experiences. With our service offerings, we provide powerful
                  and innovative software development solutions delivered in a
                  transparent and trustworthy collaboration.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {CustomSoftDevServicesList.map((data, index) => (
              <div className="col-lg-4 col-md-4 my-4" key={index}>
                <div className="hyperleger-service-inner style-3-for-hyperleger">
                  <div className="details">
                    <h4 className="mb-4">{data.title}</h4>
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
