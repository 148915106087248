import React from "react";
import { CryptoDevFeaturesList } from "../../scripts/ServicesLists";
export default function Features() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-10 col-md-12">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3">
                  <span>Our Customized</span> Crypto Trading Bots' Features
                </h2>
                <p className="">
                  Our specialized crypto trading bots are crafted to minimize
                  your trading activities. Our crypto trading bots enable you to
                  trade your cryptocurrencies efficiently on various crypto
                  exchange platforms.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {CryptoDevFeaturesList.map((data, index) => (
              <div className="col-lg-3 col-md-6" key={index}>
                <div className="crypto-feature-service-inner style-3-for-crypto-feature px-4 py-4">
                  <div className="thumb">
                    <img src={data.img} alt="img" />
                  </div>
                  <div className="details">
                    <h5>{data.title}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
