import React from "react";
import Slider from "react-slick";

export default function TechnologyStack(props) {
  const getSliderSettings = (itemsLength) => {
    const slidesToShow = Math.min(itemsLength, 5);
    const slidesToScroll = Math.min(itemsLength, 1);
    return {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToScroll,
      speed: 1000,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: Math.min(itemsLength, 3),
            slidesToScroll: Math.min(itemsLength, 1),
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: Math.min(itemsLength, 2),
            slidesToScroll: Math.min(itemsLength, 1),
            initialSlide: 2,
            arrows: false,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    };
  };
  return (
    <div>
      {/* career area start */}
      <div className="career-section pd-top-120 pd-bottom-120 ">
        <div className="container">
          <div className="row justify-content-center">
            <iv className="col-lg-8">
              <div className="section-title text-center">
                <h2 className="sectitle-lg">
                  <span>{props.bluetext}</span> {props.title}
                </h2>
              </div>
            </iv>
          </div>
          <div className="tech-stack-wrap-area">
            <nav>
              <div
                className="nav nav-tabs tech-stack-nav-tab"
                id="nav-tab"
                role="tablist"
              >
                {props.listname.map((data, index) => (
                  <button
                    key={data.btnid}
                    className={`nav-link ${index === 0 ? "active" : ""}`}
                    id={data.btnid}
                    data-bs-toggle="tab"
                    data-bs-target={data.targetid}
                    type="button"
                    role="tab"
                    aria-controls={data.tappaneid}
                    aria-selected={index === 0 ? "true" : "false"}
                  >
                    {data.btntitle}
                  </button>
                ))}
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              {props.listname.map((data, index) => (
                <div
                  key={data.tappaneid}
                  className={`tab-pane fade ${
                    index === 0 ? "show active" : ""
                  }`}
                  id={data.tappaneid}
                  role="tabpanel"
                  aria-labelledby={data.btnid}
                >
                  <div className="row py-5 px-3">
                    <Slider {...getSliderSettings(data.childdata.length)}>
                      {data.childdata.map((child_data) => (
                        <div className="col-md-2" key={child_data.id}>
                          <div className="p-3 text-center" style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                            <div className="thumb mb-3">
                              <img src={child_data.logo} alt="img" />
                            </div>
                            <div className="details">
                              <h6>{child_data.title}</h6>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* career area end */}
    </div>
  );
}
