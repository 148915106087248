import React from "react";
import { HyperLegerChooseUsList } from "../../scripts/ServicesLists";

export default function WhyChosseUs() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3">
                  <span>Why Choose TABS </span>for Hyperledger Blockchain
                  Services?
                </h2>
                <p className="">
                  Partner with a team of expert Hyperledger developers who
                  specialize in creating innovative blockchain solutions
                  tailored to your specific project needs. Our team of experts
                  is well-equipped to deliver efficient and comprehensive
                  Hyperledger-based blockchain solutions for a wide range of
                  industrial applications. We understand the importance of
                  meeting deadlines and ensuring the success of your
                  organization.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {HyperLegerChooseUsList.map((data, index) => (
              <div className="col-lg-6 col-md-6 my-4" key={index}>
                <div className="dapp-dev-service-inner style-3-for-dapp-dev">
                  <div className="details">
                    <h4 className="mb-4">{data.title}</h4>
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
