import React from "react";
import { CustomSoftFeatureList } from "../../scripts/ServicesLists";
export default function Features() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-100 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h2 className="title">
                  Featured <span>Industries</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            {CustomSoftFeatureList.map((data, index) => (
              <div className="col-lg-4 col-md-6 my-3" key={index}>
                <div className="cms-featured-sec-items">
                  <img className="" src={data.img} alt="img" />
                  <h6>{data.title}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </div>
  );
}
