import React from "react";
import { CryptoDevHelpList } from "../../scripts/ServicesLists";

export default function Helping() {
  return (
    <div>
      {/* ================== AboutAreaSix start  ==================*/}
      <div className="about-area  pd-top-90 pd-bottom-90">
        <div className="container custom-container">
          <div className=" z-index-2 border-radius-5 bg-gray pd-top-90 pd-bottom-90 px-5">
            <div className="row">
              <div
                className="col-lg-12"
                data-aos="fade-left"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <div className="section-title">
                  <h2 className="sectitle-lg">
                    {" "}
                    How Crypto Bots <span>Can Help</span> Your Company
                  </h2>
                </div>
                <p className="">
                  The sufficiency of an NFT marketplace is determined by
                  blockchain standards. Raise the level of your NFT marketplace
                  with the help of our technology stack.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              {CryptoDevHelpList.map((data, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="crypto-helping-service-inner style-3-for-crypto-helping px-4 py-4">
                    <div className="thumb">
                      <img src={data.img} alt="img" />
                    </div>
                    <div className="details">
                      <h5>{data.title}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* ================== AboutAreaSix End  ==================*/}
    </div>
  );
}
