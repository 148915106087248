import React from 'react'
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";

export default function Counter() {
  return (
    <div>
      {/*================= counter area start {/*=================*/}
      <div className='counter-area bg-base pd-top-60 pd-bottom-60'>
        <div className='container'>
          <div className='row'>
          <div className="section-title ">
                <h2 className="sectitle-lg text-white">
                Why Generative AI Matters? <span>Work Together</span>
                </h2>
              </div>
            <div className='col-lg-3 col-md-6'>
              <div className=''>
                <h2 className='text-white mt-3 mb-2'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp delay={0} start={0} end={97} />%
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white'>companies believe that generation AI would revolutionize their industry, but only 31% have made substantial investments in it.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className=''>
                <h2 className='text-white mt-3 mb-2'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp delay={0} start={0} end={63} />%
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white'>assert that 50% or more of their staff has undergone AI training, but that just 5% have fully integrated into the workforce.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className=''>
                <h2 className='text-white mt-3 mb-2'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp delay={0} start={0} end={47} />%
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white'>Chief Executive Officers rank information readiness as the most significant obstacle to implementing generative AI.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className=''>
                <h2 className='text-white mt-3 mb-2'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp delay={0} start={0} end={2} />%
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white'>Just 2% of businesses have reported fully implementing Responsible AI company-wide, but 31% plan to do so within the next 18 months.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*{/*================= counter area end {/*=================*/}
    </div>
  )
}
