import React from "react";
import { Link } from "react-router-dom";

export default function CtaSection() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-100 pd-bottom-90 bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col lg-10">
              <div className="section-title text-center">
                <h2 className="title">
                  Full Stack Development Team <span>Outsourcing</span> from TABS
                </h2>
              </div>
              <p className="text-center">
                Use our full stack development team as an outsourcing partner to
                take advantage of our core technology expertise and construct
                full stack web applications. To build solid solutions, you may
                tap into the pools of passionate experts that comprise the top
                1% of full stack web developers.
              </p>
              <div className="text-center mt-3">
                <Link
                  className="mt-3 btn btn-border-base"
                  data-aos="fade"
                  data-aos-delay="400"
                  data-aos-duration="1500"
                  to="/about"
                >
                  Hire Full Stack Developers
                </Link>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </div>
  );
}
