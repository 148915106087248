import React, { useState, useEffect } from "react";
// import { FaPlus, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";

const NavbarTwo = () => {
  const [active, setActive] = useState(false);
  // const [searchShow, setSearchShow] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };
  // const searchActive = () => {
  //   setSearchShow(!searchShow);
  //   console.log("hell");
  // };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <div>
      {/* search popup start
      <div
        className={searchShow ? "td-search-popup active" : "td-search-popup "}
        id='td-search-popup'
      >
        <form action='/' className='search-form'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              placeholder='Search.....'
            />
          </div>
          <button type='submit' className='submit-btn'>
            <FaSearch />
          </button>
        </form>
      </div>
      {/* search popup end
      <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id='body-overlay'
      ></div>*/}
      {/* ==================== Navbar Two Start ====================*/}
      <nav className={`navbar navbar-area navbar-area-2 fixed-top navbar-expand-lg ${scrolled ? 'navbar-bgg' : ''}`} >
        <div className='container nav-container custom-container'>
          <div className='responsive-mobile-menu '>
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target='#itech_main_menu'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
          </div>
          <div className='logo'>
            <Link to='/'>
              <img src='assets/img/tablogo-03c.png' alt='img' />
            </Link>
          </div>
          {/*<div className='nav-right-part nav-right-part-mobile'>
            <span className='search-bar-btn' onClick={searchActive}>
              <FaSearch />
            </span>
          </div>*/}
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id='itech_main_menu'
          >
            <ul className='navbar-nav menu-open text-lg-center'>
              <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/about'>About Us</Link>
              </li>
              <li className='menu-item-has-children'>
              <Link>Services</Link>
                <ul className='sub-menu row g-3'>
                <div className="col-lg-4 m-0">
                  <li>
                    <Link to='/web-development'>Web Development</Link>
                  </li>
                  <li>
                    <Link to='/full-stack-development'>Full Stack Development</Link>
                  </li>
                  <li>
                    <Link to='/CMS-development'>CMS Development</Link>
                  </li>
                  <li>
                    <Link to='/custom-development'>Custom Software</Link>
                  </li>
                  </div>
                  <div className="col-lg-4 m-0"> 
                  <li>
                  <Link to='/crypto-development'>Crypto Development</Link>
                </li>
                <li>
                  <Link to='/smartcontract-development'>Smart Contract</Link>
                </li>
                <li>
                <Link to='/generative-ai'>Generative AI</Link>
              </li>
              <li>
                <Link to='/hyperleger'>Hyperleger</Link>
              </li>
                </div>
                  <div className="col-lg-4 m-0">
                  <li>
                  <Link to='/dapp-development'>Dapp Development</Link>
                </li>
                <li>
                <Link to='/data-analytics'>Data Alaytics</Link>
              </li>
              <li>
                <Link to='/data-forecasting'>Data Forecasting</Link>
              </li>
                  </div>
                </ul>
              </li>
             {/*} <li className='menu-item-has-children'>
                <a href='/'>Pages</a>
                <ul className='sub-menu'>
                  <li>
                    <Link to='/about'>About Us</Link>
                  </li>
                  <li>
                    <Link to='/team'>Team</Link>
                  </li>
                  <li>
                    <Link to='/team-details'>Team Details</Link>
                  </li>
                  <li>
                    <Link to='/case-study-details'>Case Study Details</Link>
                  </li>
                </ul>
              </li>
              <li className='menu-item-has-children'>
                <a href='/'>Blog</a>
                <ul className='sub-menu'>
                  <li>
                    <Link to='/blog'>Blog</Link>
                  </li>
                  <li>
                    <Link to='/blog-details'>Blog Details</Link>
                  </li>
                </ul>
          </li> 
              <li>
                <Link to='/contact'>Contact Us</Link>
              </li>*/}
              <li>
                <Link to='/contact'>Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className='nav-right-part nav-right-part-desktop align-self-center'>
            <Link className='btn btn-border-base' to='/contact'>
              Get Started
            </Link>
        </div>
        </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </div>
  );
};

export default NavbarTwo;
