import React from "react";

export default function GoLang() {
  return (
    <div>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className="about-area pd-top-120 pd-bottom-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div
                className="about-thumb-inner  me-xl-5 "
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="main-img"
                  src="assets/img/images/Go-Logo_Black.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-9 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title ">
                <h6 className="sub-title">DEVELOPMENT LANGUAGE</h6>
                <h2 className="sectitle-lg ">
                  <span>Go Lang</span>
                </h2>
                <p className="content my-3 mb-xl-5">
                  We utilize the Go programming language for a variety of chain
                  code components within Hyperledger Fabric. Our team at TABS is
                  well-versed in multiple programming languages, including
                  Python and Rust. This expertise allows us to create the server
                  component of Hyperledger Indy, known as the Indy node, as well
                  as the Indy SDK. Our blockchain professionals are highly
                  skilled in Java, and Hyperledger Besu is an open-source
                  Ethereum client developed under the Apache 2.0 license.
                  Applications that operate on top of the Sawtooth platform can
                  be created using REST API and SDKs in various languages such
                  as Python, C++, Go, Java, JavaScript, and Rust. In addition,
                  Solidity enables the creation of smart contracts that can be
                  utilized with the Seth transaction family.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </div>
  );
}
