import React from "react";
// import { Link } from "react-router-dom";
// import { FaRegCircle } from "react-icons/fa";

export default function OurServices() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-120 pd-bottom-90 bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="main-img-wrap">
                <img
                  className="main-img"
                  src="assets/img/images/generativeai-1.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-3">
              <div className="media-for-choose-us mb-4">
                <div className="media-left me-3">
                  <img src="assets/img/icon/right-arrows.png" alt="img" />
                </div>
                <div className="media-body-for-choose-us">
                  <h4>Leverage AI for a competitive edge </h4>
                  <p>
                    To optimize processes and improve the user experience, gain
                    an advantage over competitors by using AI for instruction,
                    assistance, and success analysis.
                  </p>
                </div>
              </div>
              <div className="media-for-choose-us mb-4">
                <div className="media-left me-3">
                  <img src="assets/img/icon/right-arrows.png" alt="img" />
                </div>
                <div className="media-body-for-choose-us">
                  <h4>Master the art of brand recognition</h4>
                  <p>
                    Achieve even greater success in the market by enhancing
                    marketing techniques, increasing customer involvement, and
                    nurturing brand loyalty.
                  </p>
                </div>
              </div>
              <div className="media-for-choose-us mb-4">
                <div className="media-left me-3">
                  <img src="assets/img/icon/right-arrows.png" alt="img" />
                </div>
                <div className="media-body-for-choose-us">
                  <h4>
                    Uncover valuable insights from your organization's data
                  </h4>
                  <p>
                    Take advantage of data-driven innovation, improve cost
                    efficiency, fortify your position in the market, and use
                    knowledge based on facts.
                  </p>
                </div>
              </div>
              <div className="media-for-choose-us mb-4">
                <div className="media-left me-3">
                  <img src="assets/img/icon/right-arrows.png" alt="img" />
                </div>
                <div className="media-body-for-choose-us">
                  <h4>Achieve mastery in your field</h4>
                  <p>
                    Maximize efficiency in knowledge gathering and summarization
                    while promoting effective teamwork and open dialogue by
                    utilizing generative AI.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
