import React from "react";
import { CryptoDevBenefitsList } from "../../scripts/ServicesLists";

export default function Benefits() {
  return (
    <div>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative bg-gray pd-top-120 pd-bottom-90">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="section-title ">
                <h2 className="sectitle-lg mb-3">
                  The <span>Advantages</span> of Building a Crypto Trading Bot
                </h2>
                <p className="">
                  In the ever-changing world of cryptocurrencies, automated
                  trading bots are incredibly useful tools for improving trade
                  efficiency, profitability, and risk management.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {CryptoDevBenefitsList.map((data, index) => (
              <div className="col-lg-4 col-md-6 my-4" key={index}>
                <div className="dapp-dev-service-inner style-3-for-dapp-dev">
                  <div className="details">
                    <h4 className="mb-4"> {data.title}</h4>
                    <p>{data.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </div>
  );
}
