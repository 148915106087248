import React from "react";
import SvgArrow from "./components/SvgArrow";
import SvgArrowLeft from "./components/SvgArrowLeft";

export default function Benefits() {
  return (
    <div>
      {/* =============== About Area Three End ===============*/}
      <div className="about-area pd-top-120 pd-bottom-110">
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-10">
              <div className="section-title ">
                <h2 className="sectitle-lg">
                  <span>Benefits</span> of Working with TABS
                </h2>
              </div>
              <p className="">
                The characteristics that set TABS apart from our competition are
                the scalable web apps that we build using modern frameworks,
                structured architecture patterns, and user-centric UI/UX
                designs. To sum up, we guarantee punctuality and high-quality
                work with our bespoke web application development services
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner p-xl-5 pt-4 "
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                {/* <img
                  className="animate-main-img hidden-for-mobile"
                  src="assets/img/images/web-1.png"
                  alt="img"
                />*/}
               <img
                  className="main-img m-md-4"
                  src="assets/img/images/web-1.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 align-self-center "
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h2 className="title">
                  Advanced web applications built with{" "}
                  <span>contemporary frameworks</span>
                </h2>
                <p className="content mb-4 mb-xl-5">
                  We have been developing top-notch B2B and B2C apps for over
                  Six years, with an emphasis on M&E, healthcare, financial
                  technology, transportation, RTC, advertising technology,
                  electronic learning, and data analytics.
                </p>
              </div>
            </div>
          </div>
          <div className="row text-center hidden-for-mobile">
            <SvgArrow />
          </div>
          <div className="row reverse-row">
            <div
              className="col-lg-6  align-self-center "
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h2 className="title">
                  <span>Scalable system development</span> using structured
                  architectural patterns
                </h2>
                <p className="content mb-4 mb-xl-5">
                  In accordance with Google's concept of "progressiveness," we
                  assist in the development of large-scale web apps that are
                  offline-available, simple to install on any device, and run
                  quickly even on 2G networks.
                </p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div
                className="about-thumb-inner p-xl-5 pt-4 "
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                {/*<img
                  className="animate-main-img hidden-for-mobile"
                  src="assets/img/images/web-2.png"
                  alt="img"
                />*/}
                <img
                  className="main-img m-md-4"
                  src="assets/img/images/web-2.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div className="row text-center hidden-for-mobile">
            <SvgArrowLeft />
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner p-xl-5 pt-4 "
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
            {/*}   <img
              className="animate-main-img hidden-for-mobile"
              src="assets/img/images/web-3.png"
              alt="img"
            />*/}
               <img
                  className="main-img m-md-4"
                  src="assets/img/images/web-3.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 align-self-center "
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h2 className="title">
                  Great <span>web apps</span> start with great front-end
                </h2>
                <p className="content mb-4 mb-xl-5">
                  Damage to your business line could result from out-of-date UX
                  design, slow loading speeds, and bad performance. Providing
                  experiences that are both unique and interesting is crucial to
                  the success of your company.
                </p>
              </div>
            </div>
          </div>
          <div className="row text-center hidden-for-mobile">
            <SvgArrow />
          </div>
          <div className="row reverse-row">
            <div
              className="col-lg-6 align-self-center "
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h2 className="title">
                  Delivering <span>Innovations Worldwide</span>
                </h2>
                <p className="content mb-4 mb-xl-5">
                Over 100 clients, including leading companies in technology, beverages, hospitality, entertainment, and more, have benefited from the products developed by our web app engineering team. Modern frameworks and libraries like
                  Node.js, PostgreSQL, Python, Angular.js, React.js, and Vue.js
                  are all part of our technology stack.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-thumb-inner p-xl-5 pt-4 "
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
               {/*} <img
                  className="animate-main-img hidden-for-mobile"
                  src="assets/img/about/14.png"
                  alt="img"
                />*/}
                <img
                  className="main-img m-md-4"
                  src="assets/img/images/web-4.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Three End ===============*/}
    </div>
  );
}
